<app-modal>
  <div class="modal-l custom-questions__modal">
    <app-modal-content>
      <div class="modal-body custom-questions__content">
        <div class="custom-questions__header">
          <p class="uppercase-m semibold custom-questions__title">
            {{ 'custom_questions.apply_to_property_l' | translate }}
          </p>
          <h3 class="title-m semibold custom-questions__sub-title mb16">
            {{ 'custom_questions.increase_chances_l' | translate }}
          </h3>
          @if (!hideAnsweredQuestions()) {
            <div class="row">
              <div class="d-flex flex-column col-12">
                <p>{{ propertyMatchBean()?.property?.data?.name }}</p>
                <app-landlord-info
                  [name]="propertyMatchBean()?.property?.customer?.name"
                  [logo]="propertyMatchBean()?.property?.customer?.logo"
                  [showInCooperationWith]="false"
                ></app-landlord-info>
              </div>
            </div>
          }
        </div>

        <app-custom-questions-modal-content
          [control]="control"
          [customQuestions]="customQuestions"
          [hierarchicalRootQuestionControl]="hierarchicalRootQuestionControl"
          [hierarchicalRootQuestions]="rootQuestions"
          [isProcessing]="isProcessing$ | async"
          [hierarchicalQuestionErrorMessage]="
            hierarchicalQuestionErrorMessage$ | async
          "
          (hierarchicalFormValidityChange)="
            onHierarchicalFormValidityChange($event)
          "
          (formValidityChange)="onFormValidityChange($event)"
        >
        </app-custom-questions-modal-content>
      </div>
    </app-modal-content>

    <app-modal-footer>
      <div class="modal-footer custom-questions__footer">
        <app-button
          (clickEvent)="dismiss()"
          [disabled]="isProcessing$ | async"
          [type]="ButtonTypeEnum.LIGHT_BORDERED"
        >
          {{ 'general.cancel_a' | translate }}</app-button
        >
        <app-button
          (clickEvent)="save()"
          [disabled]="
            hierarchicalRootQuestionControl.invalid ||
            control.invalid ||
            (isProcessing$ | async)
          "
          [loading]="isProcessing$ | async"
        >
          {{ 'custom_questions.save_answers_a' | translate }}
        </app-button>
      </div>
    </app-modal-footer>
  </div>
</app-modal>
