<div class="action-button__content">
  @if (buttonType === 'NEW_MATCH') {
    <app-button
      [disabled]="disabled()"
      (clickEvent)="onApply()"
      [type]="'primary'"
      >{{ 'property.apply_now_a' | translate }}</app-button
    >
  }
  @if (
    buttonType === 'WAITING_FOR_LANDLORD' ||
    buttonType === 'NO_OPEN_SLOTS' ||
    buttonType === 'INTENT'
  ) {
    <div>
      @if (!profileComplete()) {
        <app-button
          (clickEvent)="onEditProfile()"
          [type]="'light-bordered'"
          [iconLeft]="'edit'"
        >
          {{ 'property.complete_profile_a' | translate }}
        </app-button>
      }
      @if (profileComplete()) {
        <app-button
          [disabled]="disabled()"
          (clickEvent)="onEditProfile()"
          [type]="'primary'"
          >{{ 'profile.edit_profile_a' | translate }}</app-button
        >
      }
    </div>
  }
  @if (buttonType === 'ALL_APPOINTMENTS_DECLINED') {
    <app-button
      [disabled]="disabled()"
      (clickEvent)="onNoFittingAppointment()"
      [type]="'primary'"
    >
      {{ 'property.no_fitting_appointment_a' | translate }}
    </app-button>
  }
  @if (buttonType === 'INVITED_TO_VIEWING') {
    <app-button
      [disabled]="disabled()"
      (clickEvent)="onViewInvitation()"
      [type]="'primary'"
    >
      {{ 'property.view_invitation_a' | translate }}
    </app-button>
  }
  @if (buttonType === 'ATTENDING_TO_VIEWING') {
    <app-button
      [disabled]="disabled()"
      (clickEvent)="onViewAppointment()"
      [type]="'primary-inverse'"
      [elevation]="1"
    >
      <div class="action-button__calendar">
        <div class="calendar__time">
          <svg-icon
            src="/assets/images/icons/calendar.svg"
            [applyClass]="true"
            [svgStyle]="{ 'width.px': 15 }"
            class="secondary-svg-color path rect"
          ></svg-icon>
          <span class="pl5 pr5">{{
            item()?.upcomingAppointmentDate | appDateTime
          }}</span>
          <svg-icon
            src="/assets/images/icons/icon-clock.svg"
            [applyClass]="true"
            [svgStyle]="{ 'width.px': 15 }"
            class="secondary-svg-color path circle polyline"
          ></svg-icon>
          <span class="pl10">{{
            item()?.upcomingAppointmentDate
              | appDateTime: { withDate: false, withTime: true }
          }}</span>
        </div>
      </div>
    </app-button>
  }
  @if (buttonType === 'DECLARE_INTENT') {
    <div class="row m0 intent-buttons__container">
      <app-intent-buttons
        [disabled]="disabled()"
        (setIntent)="onSetIntent($event)"
      >
      </app-intent-buttons>
    </div>
  }
</div>
