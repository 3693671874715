import { Component, inject, input, output } from '@angular/core';
import { WINDOW_REF } from '@ui/legacy-lib';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { ComponentsModule } from '@ui/legacy-lib';

@Component({
  selector: 'app-moving-auction-card',
  templateUrl: './moving-auction-card.component.html',
  styleUrls: ['./moving-auction-card.component.scss'],

  imports: [ComponentsModule, SvgIconComponent, TranslateModule]
})
export class MovingAuctionCardComponent {
  private windowRef = inject(WINDOW_REF);

  readonly openMovingAuctionWizard = output();
  readonly showNewMarker = input(false);

  public onClickMovingAuction() {
    this.openMovingAuctionWizard.emit();
  }

  public onGoToInfo() {
    this.windowRef.open(
      'https://www.mieter.immomio.com/umzugsauktion',
      '_blank'
    );
  }
}
