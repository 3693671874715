<div class="card-header row">
  <div class="col-10 pr0">{{ title() }}</div>
  <div class="col-2 card-header__icon d-flex justify-content-end pl0">
    <svg-icon
      (click)="onEdit()"
      [src]="'/assets/images/icons/icon-edit.svg'"
      [applyClass]="true"
      [svgStyle]="{ 'height.px': 20 }"
      class="{{ 'path-fill' }}"
    ></svg-icon>
  </div>
</div>
