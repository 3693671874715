import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { CookiePreference, NameValue } from '@ui/shared/models';

import {
  ButtonComponent,
  ConfirmReasonComponent,
  CookieBannerComponent,
  FetchConstants,
  getConstants,
  getConstantsLoading,
  getCookiesPreference,
  Go,
  LoadingSpinnerComponent,
  OpenCookieSettingsModal,
  SetCookiesPreference
} from '@ui/legacy-lib';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DenyIntent } from '../../../../+state';

@UntilDestroy()
@Component({
  selector: 'app-deny-intent',
  templateUrl: './deny-intent.component.html',
  styleUrls: ['./deny-intent.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CookieBannerComponent,
    AsyncPipe,
    ConfirmReasonComponent,
    TranslateModule,
    LoadingSpinnerComponent,
    ButtonComponent
  ]
})
export class DenyIntentComponent implements OnInit {
  public loading$: Observable<boolean>;
  public cookiesPreference: CookiePreference;
  public token: string;
  public reasonTypeModel: string;
  public otherReasonText: string;
  public reasons: NameValue[];
  private store = inject(Store);
  private route = inject(ActivatedRoute);

  ngOnInit() {
    this.token = this.route.snapshot.queryParams.token || '';
    this.store.dispatch(new FetchConstants());

    this.loading$ = this.store.select(getConstantsLoading);

    this.store
      .select(getCookiesPreference)
      .pipe(
        filter(cookiesPreference => !!cookiesPreference),
        untilDestroyed(this)
      )
      .subscribe(
        cookiesPreference => (this.cookiesPreference = cookiesPreference)
      );

    this.store
      .select(getConstants)
      .pipe(
        filter(constants => !!constants),
        untilDestroyed(this)
      )
      .subscribe(constants => (this.reasons = constants.refusalReasonTypes));
  }

  public onDenyIntent() {
    const payload = {
      intent: false,
      reasonType: this.reasonTypeModel,
      otherReasonText: this.otherReasonText
    };
    this.store.dispatch(
      DenyIntent({ declareIntentData: payload, token: this.token })
    );
    this.store.dispatch(new Go({ path: ['/login'] }));
  }

  public acceptCookies(payload: CookiePreference) {
    this.store.dispatch(new SetCookiesPreference(payload));
  }

  public customCookieSettings(payload: CookiePreference) {
    this.store.dispatch(new OpenCookieSettingsModal(payload, true));
  }
}
