import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-cost-details-cell',
  templateUrl: './cost-details-cell.component.html',
  styleUrls: ['./cost-details-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

  imports: [TranslateModule, CurrencyPipe]
})
export class CostDetailsCellComponent {
  readonly translationKey = input<string>(undefined);
  readonly textInsteadOfProperty = input<string>(undefined);
  readonly property = input<number>(undefined);
  readonly additionalText = input<string>(undefined);
  readonly showPlusVat = input(false);
}
