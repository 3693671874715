import { Component, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from '@ui/legacy-lib';

@Component({
  selector: 'app-schufa-success-modal',
  templateUrl: './schufa-success-modal.component.html',
  styleUrls: ['./schufa-success-modal.component.scss'],

  imports: [ComponentsModule, TranslateModule]
})
export class SchufaSuccessModalComponent {
  private ngbActiveModal = inject(NgbActiveModal);

  public dismiss() {
    this.ngbActiveModal.dismiss();
  }

  public close() {
    this.ngbActiveModal.close();
  }
}
