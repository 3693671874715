<form [formGroup]="form()" class="contract-sign-wizard-personal-info">
  <div class="row">
    <div class="col-12">
      <app-digital-contract-signer
        [form]="signerForm"
        [genderTypes]="[]"
        [readOnly]="true"
        [isPhoneRequired]="isPhoneRequired"
        [internationalPhoneOptions]="internationalPhoneOptions"
      >
      </app-digital-contract-signer>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="confirm-tenant-data-checkbox">
        <app-form-field>
          <app-checkbox
            [required]="true"
            [label]="
              'digital_contract.wizard_sign.confirm_tenant_data_l' | translate
            "
            formControlName="confirmData"
          ></app-checkbox>
        </app-form-field>
      </div>
    </div>
  </div>
  <div class="row justify-content-center mt20 mb20">
    <div class="col-12">
      <app-button
        [type]="'negative'"
        [disabled]="isButtonDisabled"
        (clickEvent)="onIncorrectTenantData()"
      >
        {{ 'digital_contract.wizard_sign.notify_landlord_a' | translate }}
      </app-button>
    </div>
  </div>
</form>
