<form [formGroup]="formGroup" class="object-information">
  <div formGroupName="propertyData">
    <div class="object-information__main-header">
      <span>{{ 'rent_deposit.object_information.title_l' | translate }}</span>
    </div>

    <div class="object-information__sub-header">
      <span>{{
        'rent_deposit.object_information.object_address_title' | translate
      }}</span>
      <app-hint class="pl6">{{
        'rent_deposit.object_information.object_hint_title_m' | translate
      }}</app-hint>
    </div>

    <div class="row">
      <div class="col-12">
        <app-address-form
          [formGroup]="addressForm"
          [required]="true"
          [showDistricts]="false"
          [showRegion]="false"
          [config]="addressFormConfig"
        >
        </app-address-form>
      </div>
    </div>
  </div>

  <div class="object-information__sub-header">
    <span>{{
      'rent_deposit.object_information.role_landlord_l' | translate
    }}</span>
  </div>

  <div formGroupName="landlordData">
    <div class="row">
      <div class="col-12">
        <app-form-field>
          <app-flat-select
            required
            [items]="landlordOptions()"
            formControlName="selectedRole"
          >
          </app-flat-select>
        </app-form-field>
      </div>
    </div>

    @if (isLegalPerson) {
      <div class="row">
        <div class="col-6">
          <app-form-field>
            <app-form-field-label [showRequiredMarker]="true">{{
              'general.company_name_l' | translate
            }}</app-form-field-label>
            <input
              appInput
              type="text"
              required
              [placeholder]="'forms.type_name' | translate"
              formControlName="name"
              class="form-control"
            />
          </app-form-field>
        </div>
      </div>
    }
    @if (isNaturalPerson) {
      <div class="object-information__sub-header">
        <span>{{
          'rent_deposit.object_information.gender_landlord_l' | translate
        }}</span>
      </div>
      <div class="row">
        <div class="col-12">
          <app-form-field>
            <app-flat-select
              required
              [items]="genderTypes()"
              formControlName="gender"
            >
            </app-flat-select>
          </app-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <app-form-field>
            <app-form-field-label [showRequiredMarker]="true">{{
              'general.first_name_l' | translate
            }}</app-form-field-label>
            <input
              appInput
              type="text"
              required
              [placeholder]="'forms.type_name' | translate"
              formControlName="firstname"
              class="form-control"
            />
          </app-form-field>
        </div>
        <div class="col-6">
          <app-form-field>
            <app-form-field-label [showRequiredMarker]="true">{{
              'general.last_name_l' | translate
            }}</app-form-field-label>
            <input
              appInput
              type="text"
              required
              [placeholder]="'forms.type_name' | translate"
              formControlName="lastname"
              class="form-control"
            />
          </app-form-field>
        </div>
      </div>
    }

    <div class="row mt10">
      <div class="col-12">
        <app-address-form
          [formGroup]="landlordAddressForm"
          [showDistricts]="false"
          [showRegion]="false"
          [required]="true"
          [config]="addressFormConfig"
        >
        </app-address-form>
      </div>
    </div>
  </div>

  <div class="object-information__sub-header">
    <span>{{
      'rent_deposit.object_information.rental_contract_title_l' | translate
    }}</span>
  </div>

  <div formGroupName="contractData">
    <div class="row">
      <div class="col-6">
        <app-form-field>
          <app-form-field-label [showRequiredMarker]="true">{{
            'rent_deposit.object_information.contract_sign_date_l' | translate
          }}</app-form-field-label>
          <app-date
            formControlName="rentalContractSignDate"
            [required]="true"
            [startDate]="startDate"
            [minDate]="minDate"
            [maxDate]="maxDateContractSign"
          >
          </app-date>
        </app-form-field>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 p0">
      <!--   rent_deposit.complete_a   -->
      <app-wizard-footer
        [disabled]="formGroup.invalid"
        [hasNext]="true"
        [hasPrevious]="true"
        (nextStep)="nextStep()"
        (previousStep)="previousStep()"
      >
      </app-wizard-footer>
    </div>
  </div>
</form>
