import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit
} from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';

import {
  Appointment,
  AppointmentAcceptanceCancelReason,
  AppointmentAcceptanceShortInfo,
  AppointmentInvitationState,
  AppointmentPropertyShortInfo,
  AppointmentsBundles,
  AppointmentSelection,
  NameValue
} from '@ui/shared/models';
import {
  AppointmentsModalComponent,
  ConfirmReasonModalComponent,
  getConstants,
  ModalService,
  NoContentComponent,
  sharedDialogConfig
} from '@ui/legacy-lib';

import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AppointmentsPropertyItemComponent } from '../../../../../../components/appointments-property-item/appointments-property-item.component';
import {
  AcceptGuestAppointment,
  CancelGuestAppointment,
  ExportCalendarFile,
  getGuestAppointmentsBundles,
  getGuestAppointmentsLoading,
  LoadGuestAppointments,
  NoAppointmentFitting
} from '../../../../../../+state';

@UntilDestroy()
@Component({
  selector: 'app-guest-viewing',
  templateUrl: './guest-viewing.component.html',
  styleUrls: ['./guest-viewing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AppointmentsPropertyItemComponent,
    AsyncPipe,
    NoContentComponent,
    TranslateModule
  ]
})
export class GuestViewingComponent implements OnInit {
  public appointmentsBundleData$: Observable<AppointmentsBundles[]>;
  public guestAppointmentsLoading$: Observable<boolean>;
  public token: string;
  public appointmentAcceptanceCancelReasonTypes: NameValue[];
  private store = inject(Store);
  private route = inject(ActivatedRoute);
  private modalService = inject(ModalService);

  ngOnInit() {
    this.token = this.route.snapshot.queryParams.token || '';

    this.store
      .select(getConstants)
      .pipe(
        filter(constants => !!constants),
        untilDestroyed(this)
      )
      .subscribe(
        constants =>
          (this.appointmentAcceptanceCancelReasonTypes =
            constants.appointmentAcceptanceCancelReasonTypes)
      );

    this.store.dispatch(new LoadGuestAppointments(this.token));

    this.appointmentsBundleData$ = this.store.select(
      getGuestAppointmentsBundles
    );
    this.guestAppointmentsLoading$ = this.store.select(
      getGuestAppointmentsLoading
    );
  }

  public onExportToCalendar(application: {
    property: AppointmentPropertyShortInfo;
    appointment: Appointment;
  }) {
    this.store.dispatch(new ExportCalendarFile(application, this.token));
  }

  public filterAppointments(
    appointments: Appointment[],
    appointmentAcceptances: AppointmentAcceptanceShortInfo[]
  ) {
    return appointments?.filter(
      appointment =>
        (appointment.state === AppointmentInvitationState.ACTIVE &&
          !appointment.full) ||
        appointmentAcceptances.some(
          appointmentAcceptance =>
            appointmentAcceptance.appointmentId === appointment.id
        )
    );
  }

  public onSelectAppointment(appointmentsBundles: AppointmentsBundles) {
    let appointments = appointmentsBundles.appointments.filter(
      appointment => appointment.maxInviteeCount > appointment.sizeOfAttendees
    );

    appointments = appointments
      .filter(
        appointment => appointment.maxInviteeCount > appointment.sizeOfAttendees
      )
      .filter(
        appointment =>
          !appointmentsBundles.appointmentInvitations.some(invitation => {
            return invitation.appointmentId === appointment.id;
          })
      );
    const exclusiveAppointments = appointmentsBundles.appointments
      .filter(
        appointment => appointment.maxInviteeCount > appointment.sizeOfAttendees
      )
      .filter(appointment =>
        appointmentsBundles.appointmentInvitations.some(invitation => {
          return invitation.appointmentId === appointment.id;
        })
      );

    const data = {
      ...appointmentsBundles,
      appointments,
      exclusiveAppointments
    };
    this.modalService
      .open(AppointmentsModalComponent, { data })
      .onClose()
      .subscribe((result: AppointmentSelection) => {
        if (result.appointmentAcceptanceId === 'non_fitting') {
          return this.store.dispatch(
            new NoAppointmentFitting({
              applicationId: result.applicationId,
              reasonType:
                AppointmentAcceptanceCancelReason.APPOINTMENT_DOES_NOT_FIT
            })
          );
        }
        const action = new AcceptGuestAppointment(
          result.appointment.id.toString(),
          this.token
        );
        return this.store.dispatch(action);
      });
  }

  public onCancelAppointment(appointmentId: string) {
    this.modalService
      .open(ConfirmReasonModalComponent, {
        data: {
          ...sharedDialogConfig.appointment.cancelAppointment,
          reasons: this.appointmentAcceptanceCancelReasonTypes
        }
      })
      .onClose()
      .subscribe(data =>
        this.store.dispatch(
          new CancelGuestAppointment(
            {
              ...data,
              appointmentId: appointmentId
            },
            this.token
          )
        )
      );
  }
}
