<div class="schufa__wizard">
  <app-wizard
    [steps]="allSteps"
    [currentStepNumber]="currentStep$ | async"
    [currentStepValid]="(currentForm$ | async)?.valid"
    [isProcessing]="processing$ | async"
    [hideButtonContainer]="true"
    [wrapStepContainer]="true"
  >
    <app-schufa-personal-form
      *appWizardStep="let change"
      [genderTypes]="genderTypes"
      [form]="personalInformation"
      [stepChange]="change"
      [steps]="allSteps"
      [stepIndex]="0"
      [personalDataActionState]="personalDataActionState$ | async"
      [personalDataResonse]="personalDataResonse$ | async"
      (retry)="onRetry()"
      (onNextStep)="onSchufaPersonalData($event)"
    ></app-schufa-personal-form>
    <app-schufa-payment-order
      *appWizardStep="let change"
      [form]="payment"
      [stepChange]="change"
      [steps]="allSteps"
      [stepIndex]="1"
      [paymentActionState]="paymentActionState$ | async"
      [orderActionState]="orderActionState$ | async"
      [paymentResponse]="paymentResponse$ | async"
      [paymentErrorCount]="paymentErrorCount$ | async"
      [personalData]="personalData.value"
      (openSchufaPaymentMoreInformation)="onOpenSchufaPaymentMoreInformation()"
      (onNextStep)="onSchufaPayment()"
    ></app-schufa-payment-order>
    <app-schufa-delivery
      *appWizardStep="let change"
      [form]="delivery"
      [stepChange]="change"
      [steps]="allSteps"
      [stepIndex]="2"
      [personalData]="personalData.value"
      [deliveryActionState]="deliveryActionState$ | async"
      [deliveryResponseError]="deliveryResponseError"
      (schufaIdentify)="onSchufaIdentify($event)"
      (onCancel)="cancel()"
    ></app-schufa-delivery>
    <app-schufa-finish
      *appWizardStep="let change"
      [form]="finish"
      [stepChange]="change"
      [steps]="allSteps"
      [stepIndex]="3"
      [deliveryData]="delivery.value"
      [deliveryResponse]="deliveryResponse$ | async"
      [isLoggedIn]="!!userData()"
      [deliveryActionState]="deliveryActionState$ | async"
      (uploadCreditReport)="onUploadCreditReport($event)"
      (downloadCreditReportPDF)="onDownloadCreditReportPDF($event)"
      (login)="onLogin()"
      (finish)="onFinish()"
    ></app-schufa-finish>
  </app-wizard>
</div>
