import { Component, inject, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import { ComponentsModule, Go, isNumber } from '@ui/legacy-lib';

import { TranslateModule } from '@ngx-translate/core';
import { NgStyle } from '@angular/common';
import { getProfileCompleteness } from '../../+state';
import { MainPageNavigation } from '../../config';

@UntilDestroy()
@Component({
  selector: 'app-profile-completeness',
  templateUrl: './profile-completeness.component.html',
  styleUrls: ['./profile-completeness.component.scss'],

  imports: [ComponentsModule, NgStyle, TranslateModule]
})
export class ProfileCompletenessComponent implements OnInit {
  public completeness: number;
  private store = inject(Store);

  get hasIncompleteProfile() {
    return isNumber(this.completeness) && this.completeness !== 100;
  }

  ngOnInit() {
    this.store
      .select(getProfileCompleteness)
      .pipe(untilDestroyed(this))
      .subscribe(completeness => (this.completeness = completeness));
  }

  public editProfile() {
    this.store.dispatch(
      new Go({
        path: [MainPageNavigation.PROFILE, 'edit', 'step']
      })
    );
  }
}
