import moment from 'moment';

import { inject, Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { Store } from '@ngrx/store';

import {
  DownloadService,
  ModalService,
  ShowError,
  ShowInfo
} from '@ui/legacy-lib';

import {
  AppointmentSelection,
  SelectAppointmentInput
} from '@ui/shared/models';

import { AppointmentFacade } from '../../core';
import { AppointmentSuccessModalComponent } from '../../components';
import { getUserData, getUserId } from '../user';
import { notificationConfig } from '../../config';
import * as fromActions from './appointments.actions';

@Injectable()
export class AppointmentsEffects {
  private actions$ = inject(Actions);
  private store = inject(Store);
  private appointmentFacade = inject(AppointmentFacade);
  loadAppointments$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.LoadAppointments>(fromActions.LOAD_APPOINTMENTS),
      withLatestFrom(this.store.select(getUserId)),
      switchMap(([_, id]) =>
        this.appointmentFacade.loadAppointments(id).pipe(
          map(
            appointmentsBundle =>
              new fromActions.LoadAppointmentsSuccess(appointmentsBundle)
          ),
          catchError(error => [
            new ShowError(notificationConfig.property.fetch.error),
            new fromActions.LoadAppointmentsFail(error)
          ])
        )
      )
    )
  );
  selectAppointment$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.SelectAppointment>(fromActions.SELECT_APPOINTMENT),
      switchMap(({ data }) =>
        this.appointmentFacade
          .selectAppointment(this.getSelectInput(data))
          .pipe(
            map(() => new fromActions.SelectAppointmentSuccess(data)),
            catchError(error => [
              new ShowError(notificationConfig.appointment.select.error),
              new fromActions.SelectAppointmentFail(error)
            ])
          )
      )
    )
  );
  cancelAppointments$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.NoAppointmentFitting>(
        fromActions.NO_APPOINTMENT_FITTING
      ),
      switchMap(({ cancelAppointmentData }) =>
        this.appointmentFacade.cancelAppointments(cancelAppointmentData).pipe(
          mergeMap(() => [
            new ShowInfo(notificationConfig.appointment.cancelAll.success),
            new fromActions.NoAppointmentFittingSuccess()
          ]),
          catchError(error => [
            new ShowError(notificationConfig.appointment.cancelAll.error),
            new fromActions.NoAppointmentFittingFail(error)
          ])
        )
      )
    )
  );
  switchAppointment$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.SwitchAppointment>(fromActions.SWITCH_APPOINTMENT),
      switchMap(({ data }) =>
        this.appointmentFacade
          .switchAppointment(this.getSelectInput(data))
          .pipe(
            map(() => new fromActions.SwitchAppointmentSuccess(data)),
            catchError(error => [
              new ShowError(notificationConfig.appointment.cancel.error),
              new fromActions.SwitchAppointmentFail(error)
            ])
          )
      )
    )
  );
  cancelAppointment$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.CancelAppointment>(fromActions.CANCEL_APPOINTMENT),
      switchMap(({ cancelAppointmentData }) =>
        this.appointmentFacade.cancelAppointment(cancelAppointmentData).pipe(
          map(() => new fromActions.CancelAppointmentSuccess()),
          catchError(error => [
            new ShowError(notificationConfig.appointment.cancel.error),
            new fromActions.CancelAppointmentFail(error)
          ])
        )
      )
    )
  );
  private downloadService = inject(DownloadService);
  exportCalendarFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.ExportCalendarFile>(fromActions.EXPORT_CALENDAR_FILE),
      switchMap(({ application, token }) =>
        this.appointmentFacade
          .exportCalendarFile(application.appointment.id, token)
          .pipe(
            tap(file => {
              const name = application.property.name;
              const date = moment(application.appointment.date).format(
                'DDMMYYYY'
              );

              this.downloadService.download(file, `${name} ${date}.ics`);
            }),
            map(() => new fromActions.ExportCalendarFileSuccess()),
            catchError(error => [new fromActions.ExportCalendarFileFail(error)])
          )
      )
    )
  );
  private modalService = inject(ModalService);
  selectAppointmentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<
        | fromActions.SelectAppointmentSuccess
        | fromActions.SwitchAppointmentSuccess
      >(
        fromActions.SELECT_APPOINTMENT_SUCCESS,
        fromActions.SWITCH_APPOINTMENT_SUCCESS
      ),
      withLatestFrom(this.store.select(getUserData)),
      switchMap(
        ([
          {
            data: { property, appointment }
          },
          user
        ]) =>
          this.modalService
            .open(AppointmentSuccessModalComponent, {
              data: {
                schufaCard: user.customerBranding?.itpSettings?.schufaCard
              }
            })
            .onClose()
            .pipe(
              map(
                () =>
                  new fromActions.ExportCalendarFile({ property, appointment })
              )
            )
      )
    )
  );

  public getSelectInput(selection: AppointmentSelection) {
    const { appointment, appointmentAcceptanceId, applicationId } = selection;

    return {
      appointmentId: appointment?.id,
      appointmentAcceptanceId,
      applicationId
    } as SelectAppointmentInput;
  }
}
