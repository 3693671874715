import { Component, inject, OnInit, input, output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

import {
  APPLICATION_STATUS_CONFIGS,
  ClipboardService,
  ComponentsModule,
  DEFAULT_APPLICATION_STATUS,
  DownloadService,
  LocalStorageService,
  ModalService,
  TenantApplicationStatusConfig
} from '@ui/legacy-lib';

import {
  ApplicantStatus,
  Constructor,
  PropertyApplicationStatus,
  PropertyBean,
  PropertyMatchBean
} from '@ui/shared/models';
import { mixinPropertiesActions } from '../../screens/objects/components/properties/mixin';
import { UrlUtilService } from '../../core';
import { getProcessApplicationActionState } from '../../+state';

class PropertyCardBase {}

const _PropertyCardComponentMixinBase =
  mixinPropertiesActions<Constructor>(PropertyCardBase);

@UntilDestroy()
@Component({
  selector: 'app-property-card-tenant',
  templateUrl: './property-card-tenant.component.html',

  imports: [ComponentsModule]
})
export class PropertyCardTenantComponent
  extends _PropertyCardComponentMixinBase
  implements OnInit
{
  readonly propertyMatch = input<PropertyMatchBean>(undefined);
  readonly property = input<PropertyBean>(undefined);
  readonly pageView = input(false);
  readonly isApplyPage = input(false);
  readonly enableImageGallery = input(false);
  readonly showDetails = output<PropertyMatchBean>();
  public applicationStates = PropertyApplicationStatus;
  public applicationStatusConfig: TenantApplicationStatusConfig;
  public isProcessing: boolean;
  private store = inject(Store);
  private translate = inject(TranslateService);
  private urlHelperService = inject(UrlUtilService);
  private downloadService = inject(DownloadService);
  private modalService = inject(ModalService);
  private clipboardService = inject(ClipboardService);
  private localStorageService = inject(LocalStorageService);

  public get isRented(): boolean {
    return this.propertyMatch()?.status === PropertyApplicationStatus.TENANT;
  }

  public get actionNeeded() {
    const propertyMatch = this.propertyMatch();
    if (
      propertyMatch?.status === ApplicantStatus.INVITED_TO_VIEWING &&
      !propertyMatch?.appointmentSlotsAvailable &&
      this.applicationStatusConfig?.actionNeeded
    ) {
      return false;
    }
    return this.applicationStatusConfig?.actionNeeded;
  }

  public get enableExportAppointmentToCalendar() {
    const propertyMatch = this.propertyMatch();
    return (
      propertyMatch?.status === this.applicationStates.ACCEPTED ||
      propertyMatch?.status === this.applicationStates.ATTENDING_TO_VIEWING
    );
  }

  public get showAddress() {
    return (
      this.applicationStatusConfig?.forceDisplayOfAddress ||
      this.property()?.data?.showAddress
    );
  }

  public get isNotBlocked() {
    return !this.propertyMatch()?.applicationBlocked;
  }

  public get actionBadgeContent() {
    const propertyMatch = this.propertyMatch();
    switch (propertyMatch?.status) {
      case ApplicantStatus.OFFERED || ApplicantStatus.AUTO_OFFERED: {
        return this.translate.instant(
          'property.todo.answer_proposal_l'
        ) as string;
      }
      case ApplicantStatus.INVITED_TO_VIEWING: {
        return propertyMatch?.appointmentSlotsAvailable
          ? (this.translate.instant(
              'property.todo.select_appointment_l'
            ) as string)
          : false;
      }
      case ApplicantStatus.ATTENDING_TO_VIEWING: {
        const date = propertyMatch?.upcomingAppointmentDate;
        return this.translate.instant('property.todo.upcoming_appointment_l', {
          value: date ? moment(date).format('dd. DD.MM., HH:mm') : '-'
        }) as string;
      }
      case ApplicantStatus.DECLARE_INTENT: {
        return this.translate.instant(
          'property.todo.give_feedback_l'
        ) as string;
      }
      default:
        return null;
    }
  }

  public get propertyData() {
    const {
      type,
      externalId,
      wbs: housingPermissionNeeded,
      marketingType
    } = this.property();
    const {
      name,
      address,
      size,
      rooms,
      halfRooms,
      totalRentGross,
      availableFrom,
      attachments,
      documents,
      salesData,
      objectType,
      commercialData
    } = this.property().data;

    return {
      type,
      externalId,
      name,
      address,
      size,
      rooms,
      halfRooms,
      totalRentGross,
      availableFrom,
      housingPermissionNeeded,
      attachments,
      documents,
      marketingType,
      salesData,
      objectType,
      commercialData
    };
  }

  public get landlordInfoData() {
    const property = this.property();
    return {
      name: property?.customer?.name,
      logo: property?.customer?.logo,
      logoRedirectUrl: this.urlHelperService.getLogoRedirectUrl()
    };
  }

  ngOnInit() {
    this.store
      .select(getProcessApplicationActionState)
      .pipe(untilDestroyed(this))
      .subscribe(isProcessing => (this.isProcessing = isProcessing.pending));

    this.applicationStatusConfig =
      APPLICATION_STATUS_CONFIGS[
        this.propertyMatch()?.status || DEFAULT_APPLICATION_STATUS
      ];
  }

  public showDetailsClick(propertyMatch: PropertyMatchBean) {
    if (this.pageView()) return;
    this.showDetails.emit(propertyMatch);
  }

  public removePropertyClick(propertyMatch: PropertyMatchBean) {
    this.onRemoveProperty(propertyMatch);
  }

  public downloadPDFClick(id: string) {
    this.onExportPDF(id);
  }

  public setIntent(propertyMatch: PropertyMatchBean, intent: string) {
    this.onSetIntent({
      propertyMatch,
      intent
    });
  }
}
