<app-modal-v2>
  <div class="modal-m">
    <app-modal-content>
      <div class="modal-body">
        <div class="d-flex justify-content-between">
          <div class="title-m payment-positions-request-paid-modal__title">
            {{ 'payment_request.are_you_sure' | translate }}
          </div>
          <div class="modal__close-button">
            <img
              class="payment-positions-request-paid-modal__close"
              src="/assets/images/icons/icon-close.svg"
              (click)="onDismiss()"
            />
          </div>
        </div>
        <p class="mt15">
          {{ 'payment_request.are_you_sure_notice_m' | translate }}
        </p>
        <div>
          <div class="title-s mb10">
            {{ 'payment_request.selected_positions_l' | translate }}
          </div>
          @for (position of selectedPositions(); track position.id) {
            <div class="d-flex justify-content-between ml5 mr5">
              <div>{{ position.subject }}</div>
              <div>{{ position.amount | currency: currency() }}</div>
            </div>
          }
        </div>
        <div class="mt15">
          <app-button [type]="'light-bordered'" (clickEvent)="onDismiss()"
            >{{ 'general.previous_a' | translate }}
          </app-button>
        </div>
        <div class="mt15 mb5">
          <app-button (clickEvent)="onClose()"
            >{{ 'payment_request.send_a' | translate }}
          </app-button>
        </div>
      </div>
    </app-modal-content>
  </div>
</app-modal-v2>
