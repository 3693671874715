import { inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { filter, map, take, tap, withLatestFrom } from 'rxjs/operators';
import {
  getPropertyMatchesRented,
  getPropertyMatchesRentedActionState,
  SaveMyNewHomeState
} from '../../+state';

@Injectable()
export class NewHomeResolver {
  private store = inject(Store);

  resolve() {
    return this.store.select(getPropertyMatchesRentedActionState).pipe(
      filter(state => !state.pending),
      take(1),
      withLatestFrom(this.store.select(getPropertyMatchesRented)),
      map(([_, rentedObjects]) => rentedObjects?.length > 0),
      tap(isMyNewHome =>
        this.store.dispatch(SaveMyNewHomeState({ isMyNewHome }))
      )
    );
  }
}
