import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import {
  AuthToken,
  ModalService,
  SessionStorageService,
  ShowError,
  ShowInfo,
  UserLoginSuccess
} from '@ui/legacy-lib';
import { GuestApplication } from '@ui/shared/models';
import { GuestModeFacade } from '../../core';
import { GuestRegisterModalComponent } from '../../components';
import {
  MainPageNavigation,
  notificationConfig,
  storageKeys
} from '../../config';
import * as fromActions from './guest-mode.actions';

@Injectable()
export class GuestModeEffects {
  private actions$ = inject(Actions);
  private facade = inject(GuestModeFacade);
  checkGuestApplicationPossible$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.CheckGuestApplicationPossible>(
        fromActions.CHECK_GUEST_APPLICATION_POSSIBLE
      ),
      switchMap(({ email, propertyId }) =>
        this.facade.checkGuestApplicationExists(email, propertyId).pipe(
          map(
            guestStatus =>
              new fromActions.CheckGuestApplicationPossibleSuccess(guestStatus)
          ),
          catchError(err => [
            new fromActions.CheckGuestApplicationPossibleFail(err),
            new ShowError(notificationConfig.guest.checkApplication.error)
          ])
        )
      )
    )
  );
  applyAsGuest$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.ApplyAsGuest>(fromActions.APPLY_AS_GUEST),
      switchMap(({ guestData, token }) =>
        this.facade.applyAsGuest(guestData, token).pipe(
          mergeMap(() => [
            new fromActions.ApplyAsGuestSuccess(),
            new ShowInfo(notificationConfig.guest.apply.success)
          ]),
          catchError(err => [
            new fromActions.ApplyAsGuestFail(err),
            new ShowError(notificationConfig.guest.apply.error)
          ])
        )
      )
    )
  );
  /**
   * Register after guest application or as prerequisite in order to declare intent
   */

  guestRegister$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.GuestRegister>(fromActions.GUEST_REGISTER),
      switchMap(({ userData, token, applicationIdToDeclareIntentTo }) =>
        this.facade.register(userData, token).pipe(
          mergeMap(authToken =>
            this.registerSuccess(authToken, applicationIdToDeclareIntentTo)
          ),
          catchError(err => [
            new fromActions.GuestRegisterFail(err),
            new ShowError(notificationConfig.guest.register.error)
          ])
        )
      )
    )
  );
  /**
   * Register at the end of guest apply.
   */

  guestApplicationRegister$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.GuestApplicationRegister>(
        fromActions.GUEST_APPLICATION_REGISTER
      ),
      switchMap(({ userData, token }) => {
        return this.facade.applicationRegister(userData, token).pipe(
          mergeMap(authToken => this.registerSuccess(authToken)),
          catchError(err => [
            new fromActions.GuestRegisterFail(err),
            new ShowError(notificationConfig.guest.register.error)
          ])
        );
      })
    )
  );
  guestCustomQuestionResponses$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.LoadGuestCustomQuestionResponses>(
        fromActions.LOAD_GUEST_CUSTOM_QUESTION_RESPONSES
      ),
      switchMap(({ token }) =>
        this.facade.loadCustomQuestionResponses(token).pipe(
          map(
            customQuestionResponses =>
              new fromActions.LoadGuestCustomQuestionResponsesSuccess(
                customQuestionResponses
              )
          ),
          catchError(err => [
            new fromActions.LoadGuestCustomQuestionResponsesFail(err),
            new ShowError(
              notificationConfig.guest.customQuestionResponses.error
            )
          ])
        )
      )
    )
  );
  branding$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.LoadGuestBranding>(fromActions.LOAD_GUEST_BRANDING),
      switchMap(({ token }) =>
        this.facade.loadBranding(token).pipe(
          map(branding => new fromActions.LoadGuestBrandingSuccess(branding)),
          catchError(err => [
            new fromActions.LoadGuestBrandingFail(err),
            new ShowError(notificationConfig.guest.branding.error)
          ])
        )
      )
    )
  );
  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.DeleteGuest>(fromActions.DELETE_GUEST),
      switchMap(({ token, reasonData }) =>
        this.facade.delete(reasonData, token).pipe(
          mergeMap(() => [
            new fromActions.DeleteGuestSuccess(),
            new ShowInfo(notificationConfig.guest.delete.success)
          ]),
          catchError(err => [
            new fromActions.DeleteGuestFail(err),
            new ShowError(notificationConfig.guest.delete.error)
          ])
        )
      )
    )
  );
  loadGuestAppointments$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.LoadGuestAppointments>(
        fromActions.LOAD_GUEST_APPOINTMENTS
      ),
      switchMap(({ token }) =>
        this.facade.loadGuestAppointments(token).pipe(
          mergeMap(appointmentsBundles => [
            new fromActions.LoadGuestAppointmentsSuccess(appointmentsBundles)
          ]),
          catchError(err => [
            new fromActions.LoadGuestAppointmentsFail(err),
            new ShowError(notificationConfig.guest.loadAppointment.error)
          ])
        )
      )
    )
  );
  loadGuestApplication$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.LoadGuestApplication>(
        fromActions.LOAD_GUEST_APPLICATION
      ),
      switchMap(({ token }) =>
        this.facade.loadGuestApplication(token).pipe(
          mergeMap(application => [
            new fromActions.LoadGuestApplicationSuccess(application)
          ]),
          catchError(err => [
            new fromActions.LoadGuestApplicationFail(err),
            new ShowError(notificationConfig.guest.loadApplication.error)
          ])
        )
      )
    )
  );
  loadGuestQuestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.LoadGuestHierarchicalQuestionsResponses>(
        fromActions.LOAD_GUEST_HIERARCHICAL_QUESTION_RESPONSES
      ),
      switchMap(({ token }) =>
        this.facade.loadGuestQuestions(token).pipe(
          mergeMap(application => [
            new fromActions.LoadGuestHierarchicalQuestionsResponsesSuccess(
              application
            )
          ]),
          catchError(err => [
            new fromActions.LoadGuestHierarchicalQuestionsResponsesFail(err),
            new ShowError(notificationConfig.guest.loadQuestionsResponses.error)
          ])
        )
      )
    )
  );
  acceptGuestAppointment$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.AcceptGuestAppointment>(
        fromActions.ACCEPT_GUEST_APPOINTMENT
      ),
      switchMap(({ appointmentId, token }) =>
        this.facade.acceptAppointment(appointmentId, token).pipe(
          mergeMap(() => [
            new fromActions.AcceptGuestAppointmentSuccess(),
            new ShowInfo(notificationConfig.guest.acceptAppointment.success)
          ]),
          catchError(err => [
            new fromActions.AcceptGuestAppointmentFail(err),
            new ShowError(notificationConfig.guest.acceptAppointment.error)
          ])
        )
      )
    )
  );
  cancelGuestAppointment$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.CancelGuestAppointment>(
        fromActions.CANCEL_GUEST_APPOINTMENT
      ),
      switchMap(({ cancelAppointmentData, token }) =>
        this.facade.cancelAppointment(cancelAppointmentData, token).pipe(
          mergeMap(() => [
            new fromActions.CancelGuestAppointmentSuccess(),
            new ShowInfo(notificationConfig.guest.cancelAppointment.success)
          ]),
          catchError(err => [
            new fromActions.CancelGuestAppointmentFail(err),
            new ShowError(notificationConfig.guest.cancelAppointment.error)
          ])
        )
      )
    )
  );
  guestDeclareIntent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.GuestDeclareIntent>(fromActions.GUEST_DECLARE_INTENT),
      switchMap(({ declareIntentData, token }) =>
        this.facade.declareIntent(declareIntentData, token).pipe(
          mergeMap(() => [
            new fromActions.GuestDeclareIntentSuccess(),
            new ShowInfo(notificationConfig.guest.declareIntent.success)
          ]),
          catchError(err => [
            new fromActions.GuestDeclareIntentFail(err),
            new ShowError(notificationConfig.guest.declareIntent.error)
          ])
        )
      )
    )
  );
  private modalService = inject(ModalService);
  private store = inject(Store);
  openGuestRegisterModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromActions.OpenGuestRegisterModal>(
          fromActions.OPEN_GUEST_REGISTER_MODAL
        ),
        tap(({ application, token, declareIntent }) => {
          this.modalService
            .open(GuestRegisterModalComponent, {
              backdrop: 'static',
              keyboard: false,
              data: { email: application.userProfile.email, declareIntent }
            })
            .onClose()
            .subscribe(registrationData => {
              const tenantRegisterInput =
                this.getTenantRegisterInput(application);
              tenantRegisterInput.password = registrationData.password;
              tenantRegisterInput.confirmPassword = registrationData.password;
              tenantRegisterInput.acceptPolicies =
                registrationData.acceptPolicies;
              this.store.dispatch(
                new fromActions.GuestRegister(
                  tenantRegisterInput,
                  token,
                  declareIntent && application.id
                )
              );
            });
        })
      ),
    { dispatch: false }
  );
  private sessionStorage = inject(SessionStorageService);

  private registerSuccess(
    authToken: Partial<AuthToken>,
    applicationIdToDeclareIntentTo?: string
  ) {
    this.sessionStorage.setItem(storageKeys.registeredAsGuest, true);
    const stateAfterAuth = {
      pathAfterAuth: applicationIdToDeclareIntentTo
        ? [MainPageNavigation.EXPOSE, applicationIdToDeclareIntentTo].join('/')
        : [MainPageNavigation.PROPERTIES, MainPageNavigation.APPLICATIONS].join(
            '/'
          )
    };

    const actions: Action[] = [
      new fromActions.GuestRegisterSuccess(),
      new UserLoginSuccess({ ...authToken } as AuthToken, stateAfterAuth),
      new ShowInfo(notificationConfig.guest.register.success)
    ];

    return actions;
  }

  private getTenantRegisterInput(application: GuestApplication) {
    const { userProfile } = application;
    const { email, data, address } = userProfile;
    const { firstname, name, phone } = data;
    return {
      firstName: firstname,
      lastName: name,
      email,
      password: null,
      confirmPassword: null,
      phone,
      socialLogin: false,
      acceptPolicies: false,
      address,
      searchProfile: null,
      customQuestionResponses: [],
      questionResponses: application.questionResponses
    };
  }
}
