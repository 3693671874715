<form [formGroup]="form()" class="row">
  <div class="col-12 d-flex mb15">
    <app-heading level="3" styleType="200" class="d-flex align-items-center m0">
      {{ addressTitle() }}
      <app-hint class="pl5">{{ addressHint() | translate }}</app-hint>
    </app-heading>
    @if (removable() && index() > 0) {
      <div class="col-2 col-md-1 p0">
        <app-button
          [type]="'light-bordered'"
          [iconLeft]="'delete'"
          (clickEvent)="onRemove()"
        >
        </app-button>
      </div>
    }
  </div>

  <div class="col-9 mb20">
    @if (useStreetForSearch()) {
      <app-form-field class="inline">
        <app-form-field-label [showRequiredMarker]="true">{{
          'general.street_name' | translate
        }}</app-form-field-label>
        <app-location-search-field
          [required]="true"
          [placeholder]="'forms.type_street_name' | translate"
          [removeLocality]="true"
          (locationData)="onLocationSearchSelect($event)"
          formControlName="street"
        ></app-location-search-field>
      </app-form-field>
    }
    @if (!useStreetForSearch()) {
      <app-form-field class="inline">
        <app-form-field-label [showRequiredMarker]="true">{{
          'general.street_name' | translate
        }}</app-form-field-label>
        <input
          appInput
          required
          [placeholder]="'forms.type_street_name' | translate"
          type="text"
          formControlName="street"
          class="form-control"
        />
      </app-form-field>
    }
  </div>
  <div class="col-3 mb20">
    <app-form-field class="inline">
      <app-form-field-label>{{
        'general.house_number_l' | translate
      }}</app-form-field-label>
      <input
        appInput
        type="text"
        [placeholder]="'general.house_number_l' | translate"
        formControlName="houseNumber"
        class="form-control"
      />
    </app-form-field>
  </div>
  <div class="col-4 mb20">
    <app-form-field class="inline">
      <app-form-field-label [showRequiredMarker]="true">{{
        'general.zip_code_l' | translate
      }}</app-form-field-label>
      <input
        appInput
        type="text"
        [required]="true"
        [placeholder]="'forms.type_zip_code' | translate"
        formControlName="zipCode"
        class="form-control"
      />
    </app-form-field>
  </div>
  <div class="col-8 mb20">
    <app-form-field class="inline">
      <app-form-field-label [showRequiredMarker]="true">{{
        'general.city_l' | translate
      }}</app-form-field-label>
      <input
        appInput
        type="text"
        [required]="true"
        [placeholder]="'forms.type_city' | translate"
        formControlName="city"
        class="form-control"
      />
    </app-form-field>
  </div>
</form>
