import { Routes } from '@angular/router';
import { SchufaStepGuard } from '../../core';
import {
  SchufaDeliveryComponent,
  SchufaFinishComponent,
  SchufaPaymentOrderComponent,
  SchufaPersonalFormComponent
} from './components';

export const SchufaStepsRoutes: Routes = [
  {
    path: 'personalInformation',
    component: SchufaPersonalFormComponent,
    data: {
      title: 'schufa.wizard.step_1_title'
    }
  },
  {
    path: 'payment',
    component: SchufaPaymentOrderComponent,
    canActivate: [SchufaStepGuard],
    data: {
      title: 'schufa.wizard.step_2_title',
      requiredCompletion: 'personalInformation'
    }
  },
  {
    path: 'delivery',
    component: SchufaDeliveryComponent,
    canActivate: [SchufaStepGuard],
    data: {
      title: 'schufa.wizard.step_3_title',
      requiredCompletion: 'payment'
    }
  },
  {
    path: 'finish',
    component: SchufaFinishComponent,
    canActivate: [SchufaStepGuard],
    data: {
      title: 'schufa.wizard.step_4_title',
      requiredCompletion: 'delivery'
    }
  }
];
