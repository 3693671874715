import { Component, output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { ComponentsModule } from '@ui/legacy-lib';

@Component({
  selector: 'app-get-schufa',
  templateUrl: './get-schufa.component.html',
  styleUrls: ['./get-schufa.component.scss'],

  imports: [ComponentsModule, SvgIconComponent, TranslateModule]
})
export class GetSchufaComponent {
  readonly goToSchufa = output();

  public onGoToSchufa() {
    this.goToSchufa.emit();
  }
}
