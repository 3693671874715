import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ShowError } from '@ui/legacy-lib';
import { TooltipFacade } from '../../core';
import { notificationConfig } from '../../config';
import * as fromActions from './tooltip.actions';

@Injectable()
export class TooltipEffects {
  private actions$ = inject(Actions);
  private facade = inject(TooltipFacade);

  fetchTooltipsUnseen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.fetchTooltipsUnseen),
      switchMap(({ path }) =>
        this.facade.tooltipsUnseen(path).pipe(
          map(tooltips =>
            fromActions.fetchTooltipsUnseenSuccess({ path, tooltips })
          ),
          catchError(error => [
            fromActions.fetchTooltipsUnseenFail({ error }),
            new ShowError(notificationConfig.tooltip.fetchTooltips.error)
          ])
        )
      )
    )
  );

  fetchTooltipToursUnseen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.fetchTooltipToursUnseen),
      switchMap(({ path }) =>
        this.facade.tooltipToursUnseen(path).pipe(
          map(tooltipTours =>
            fromActions.fetchTooltipToursUnseenSuccess({ path, tooltipTours })
          ),
          catchError(error => [
            fromActions.fetchTooltipsUnseenFail({ error }),
            new ShowError(notificationConfig.tooltip.fetchTooltips.error)
          ])
        )
      )
    )
  );

  setTooltipsSeen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setTooltipsSeen),
      switchMap(({ ids }) =>
        this.facade.tooltipsSeen(ids).pipe(
          map(() => fromActions.setTooltipsSeenSuccess({ ids })),
          catchError(error => [
            fromActions.setTooltipsSeenFail({ error, ids }),
            new ShowError(notificationConfig.tooltip.setTooltipsAsSeen.error)
          ])
        )
      )
    )
  );

  setTooltipTourSeen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setTooltipTourSeen),
      switchMap(({ tooltipTour }) => {
        const ids = tooltipTour.tourElements.map(
          tourElement => tourElement.tooltip.id
        );

        return this.facade.tooltipsSeen(ids).pipe(
          map(() => fromActions.setTooltipTourSeenSuccess({ ids })),
          catchError(error => [
            fromActions.setTooltipTourSeenFail({ error, ids }),
            new ShowError(notificationConfig.tooltip.setTooltipTourSeen.error)
          ])
        );
      })
    )
  );
}
