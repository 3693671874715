import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { AuthTokenService } from '@ui/legacy-lib';
import {
  getPropertyMatchesRentedActionState,
  LoadPropertyMatchesRented
} from '../../+state';

@Injectable()
export class NewHomeGuard {
  private store = inject(Store);
  private authTokenService = inject(AuthTokenService);

  canActivate(_route: ActivatedRouteSnapshot) {
    if (!this.authTokenService.getToken().access_token) {
      return of(false);
    }
    this.store.dispatch(
      LoadPropertyMatchesRented({
        data: { page: 0, sort: 'id,asc', size: 100 },
        loadMore: false
      })
    );

    return this.store.select(getPropertyMatchesRentedActionState).pipe(
      filter(state => !state.pending),
      switchMap(() => of(true))
    );
  }
}
