import { Component, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ButtonTypeEnum, ComponentsModule } from '@ui/legacy-lib';

import { TranslateModule } from '@ngx-translate/core';
import { externalDataPrivacyLink } from '../../config';

@Component({
  selector: 'app-social-login-confirmation-modal',
  templateUrl: './social-login-confirmation-modal.component.html',
  styleUrls: ['./social-login-confirmation-modal.component.scss'],

  imports: [ComponentsModule, TranslateModule]
})
export class SocialLoginConfirmationModalComponent {
  public type: ButtonTypeEnum = ButtonTypeEnum.LIGHT_BORDERED;
  public dataProtectionLink = externalDataPrivacyLink;
  private ngbActiveModal = inject(NgbActiveModal);

  public continue() {
    this.ngbActiveModal.close();
  }

  public close() {
    this.ngbActiveModal.dismiss();
  }
}
