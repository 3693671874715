import { inject, Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Apollo, QueryRef } from 'apollo-angular';

import { filter, map } from 'rxjs/operators';

import {
  appointmentsBundlesQuery,
  AppointmentsResult,
  AuthTokenService,
  cancelAppointmentMutation,
  cancelAppointmentsMutation,
  selectAppointmentMutation,
  switchAppointmentMutation
} from '@ui/legacy-lib';

import {
  CancelAppointmentData,
  SelectAppointmentInput
} from '@ui/shared/models';
import { ENVIRONMENT_CONFIG } from '../environment';
import { ApplicationFacade } from './application.facade';
import { PropertyMatchesFacade } from './property-matches.facade';

@Injectable()
export class AppointmentFacade {
  private apollo = inject(Apollo);
  private http = inject(HttpClient);
  private applicationFacade = inject(ApplicationFacade);
  private propertySearcherFacade = inject(PropertyMatchesFacade);
  private authTokenService = inject(AuthTokenService);
  private env = inject(ENVIRONMENT_CONFIG);

  private appointmentsQuery: QueryRef<AppointmentsResult>;

  public loadAppointments(userId: string) {
    this.appointmentsQuery = this.apollo.watchQuery<AppointmentsResult>({
      query: appointmentsBundlesQuery,
      variables: { userId },
      fetchPolicy: 'no-cache'
    });

    return this.appointmentsQuery.valueChanges.pipe(
      filter(response => !response.loading || !!response.data),
      map(response => response.data.getAppointmentsBundles)
    );
  }

  public selectAppointment(data: SelectAppointmentInput) {
    const { appointmentId, applicationId } = data;
    return this.apollo.mutate({
      mutation: selectAppointmentMutation,
      variables: {
        input: {
          appointmentId,
          applicationId
        }
      },
      update: () => this.refetchAppointments(applicationId)
    });
  }

  public cancelAppointments(cancelAppointmentData: CancelAppointmentData) {
    return this.apollo.mutate({
      mutation: cancelAppointmentsMutation,
      variables: { input: cancelAppointmentData },
      update: () =>
        this.refetchAppointments(cancelAppointmentData.applicationId)
    });
  }

  public cancelAppointment(cancelAppointmentData: CancelAppointmentData) {
    const { applicationId, ...input } = cancelAppointmentData;
    return this.apollo.mutate({
      mutation: cancelAppointmentMutation,
      variables: { input },
      update: () => this.refetchAppointments(applicationId)
    });
  }

  public switchAppointment(data: SelectAppointmentInput) {
    const { appointmentId, applicationId, appointmentAcceptanceId } = data;
    return this.apollo.mutate({
      mutation: switchAppointmentMutation,
      variables: {
        input: {
          appointmentId,
          applicationId,
          appointmentAcceptanceId
        }
      },
      update: () => this.refetchAppointments(data.applicationId)
    });
  }

  public refetchAppointments(id?: string) {
    if (this.appointmentsQuery) {
      void this.appointmentsQuery.refetch();
      this.propertySearcherFacade.refetchMatch(id);
    }
  }

  public exportCalendarFile(appointmentId: string, guestToken?: string) {
    const token = this.authTokenService.getToken().access_token || guestToken;
    const headers = new HttpHeaders().append(
      'Content-Type',
      'application/json'
    );

    const path =
      this.env.graphql_host +
      (guestToken ? '/guest-appointment-ics' : '/appointment-ics');

    return this.http.post(
      path,
      { appointmentId, token },
      { headers, responseType: 'blob' }
    );
  }
}
