<div class="profile__content">
  <form [formGroup]="form()" class="profile__subform">
    <div class="mb20">
      <app-form-field>
        <app-form-field-label
          >{{ 'general.profile_picture_l' | translate }}
        </app-form-field-label>
        <app-attachments
          [showDownload]="false"
          [showRemove]="true"
          [accept]="acceptedPhotoTypes"
          [size]="acceptedPhotoSize"
          [subInformation]="
            'JPG ' +
            ('general.or_l' | translate) +
            ' PNG ' +
            ('general.file_size_up_to_l' | translate) +
            ' 20 MB'
          "
          formControlName="portrait"
        ></app-attachments>
      </app-form-field>
    </div>

    <div class="form__section card">
      <div class="card-body">
        <div formGroupName="address">
          <app-address-form
            [formGroup]="addressForm"
            [required]="false"
            [showDistricts]="false"
            [useStreetForSearch]="true"
            [config]="addressFormConfig"
            [resetValuesOnCountryChange]="true"
          >
          </app-address-form>
        </div>
        <app-form-field>
          <app-form-field-label
            >{{ 'general.telephone_l' | translate }}
          </app-form-field-label>
          <app-phone-input formControlName="phone"></app-phone-input>
        </app-form-field>
      </div>
    </div>
  </form>
</div>
