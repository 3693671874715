import { createSelector } from '@ngrx/store';

import { getMovingChecklistState } from '../reducers';
import * as fromReducer from './moving-checklist.reducer';

export const getMovingChecklist = createSelector(
  getMovingChecklistState,
  fromReducer.getMovingChecklist
);

export const getGetByPropertyActionState = createSelector(
  getMovingChecklistState,
  fromReducer.getGetByPropertyActionState
);

export const getChangeElementActionState = createSelector(
  getMovingChecklistState,
  fromReducer.getChangeElementActionState
);

export const getSetMoveInDateActionState = createSelector(
  getMovingChecklistState,
  fromReducer.getSetMoveInDateActionState
);

export const getResetActionState = createSelector(
  getMovingChecklistState,
  fromReducer.getResetActionState
);
