import { externalDataPrivacyLink } from './navigation.config';

export const footerConfig = {
  links: [
    { url: 'https://www.mieter.immomio.com/agb', name: 'general.terms' },
    {
      url: 'https://www.mieter.immomio.com/impressum',
      name: 'general.imprint'
    },
    {
      url: externalDataPrivacyLink,
      name: 'general.data_privacy'
    },
    { url: 'https://www.mieter.immomio.com/karriere', name: 'general.careers' }
  ]
};
