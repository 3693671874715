<form [formGroup]="form">
  <app-wizard
    [steps]="allSteps"
    [currentStepNumber]="currentStep$ | async"
    [currentStepValid]="(currentForm$ | async)?.valid"
    [isProcessing]="processing$ | async"
    [hideButtonContainer]="true"
    [wrapStepContainer]="true"
  >
    <app-rent-deposit-personal-information
      *appWizardStep="let change"
      formControlName="personalInformation"
      [genderTypes]="genderTypes"
      [steps]="allSteps"
      [stepIndex]="0"
      [stepChange]="change"
      (onNextStep)="nextStep()"
    >
    </app-rent-deposit-personal-information>

    <app-rent-deposit-object
      *appWizardStep="let change"
      formControlName="object"
      [genderTypes]="genderTypes"
      [landlordOptions]="landlordOptions"
      [steps]="allSteps"
      [stepIndex]="1"
      [stepChange]="change"
      (onNextStep)="nextStep()"
      (onPreviousStep)="previousStep()"
    >
    </app-rent-deposit-object>

    <app-rent-deposit-payment
      *appWizardStep="let change"
      formControlName="payment"
      [paymentOptions]="paymentOptions"
      [deliveryOptions]="deliveryOptions"
      [steps]="allSteps"
      [stepIndex]="2"
      [stepChange]="change"
      (onNextStep)="nextStep()"
      (onPreviousStep)="previousStep()"
    >
    </app-rent-deposit-payment>

    <app-rent-deposit-summary
      *appWizardStep="let change"
      [rentDepositModel]="rentDepositPayload"
      [propertyMatch]="propertyMatchBean()"
      [steps]="allSteps"
      [stepIndex]="3"
      [stepChange]="change"
      [processing]="processing$ | async"
      (privacyModal)="onPrivacyModal()"
      (edit)="onEdit($event)"
      (onComplete)="completeStep()"
      (onPreviousStep)="previousStep()"
    >
    </app-rent-deposit-summary>
  </app-wizard>
</form>
