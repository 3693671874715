import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';

import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import {
  AdblockDetectorService,
  AuthTokenService,
  BodyService,
  ComponentsModule as SharedComponentsModule,
  ComponentsModuleConfig,
  CustomSerializer,
  DateTimeService,
  EnvironmentConfig,
  FreshchatWidgetService,
  ImmomioTheme,
  InfrastructureConfig,
  InfrastructureModule,
  KeycloakTenantAuthenticationModule,
  NewRelicService,
  PipesModule,
  ThemeModule,
  ThemeUrls,
  UserConversionService
} from '@ui/legacy-lib';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IbanDirective } from 'ngx-iban';
import { provideStore } from '@ngrx/store';

import { provideEffects } from '@ngrx/effects';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideRouterStore } from '@ngrx/router-store';

import { provideNgxMask } from 'ngx-mask';
import { languageConfig, storageKeys } from './app/config';
import { AppComponent } from './app/app.component';
import { AppRouterModule } from './app/app.router';
import { CoreModule } from './app/core';
import { LandingsModule } from './app/screens/landings/landings.module';
import { FormStorageService } from './app/auth/services';
import { appReducers, effects } from './app/+state';
import { ComponentsModule } from './app/components';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const infrastructureConfig: InfrastructureConfig = {
  environment,
  apolloCacheIds: {
    MessageSource: 'messageKey'
  },
  translationKeys: {
    meta: {
      description: 'meta.description'
    }
  },
  storageKeys,
  languages: languageConfig
};

const sharedComponentsModuleConfig: ComponentsModuleConfig = {
  googleApiConfig: {
    apiKey: environment.google_api_key
  }
};

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      ServiceWorkerModule.register('/ngsw-worker.js', {
        enabled:
          environment.deploymentEnv === 'production' ||
          environment.deploymentEnv === 'sandbox'
      }),
      TranslateModule.forRoot(),
      BrowserModule,
      BrowserAnimationsModule,
      CommonModule,
      SharedComponentsModule.forRoot(sharedComponentsModuleConfig),
      PipesModule,
      FormsModule,
      ReactiveFormsModule,
      AngularSvgIconModule.forRoot(),
      NgbModule,
      InfrastructureModule.forRoot(infrastructureConfig),
      AppRouterModule,
      ComponentsModule,
      CoreModule,
      LandingsModule,
      ThemeModule.forRoot({
        themes: [ImmomioTheme],
        active:
          (environment as EnvironmentConfig)?.app_design || ThemeUrls.IMMOMIO
      }),
      IbanDirective,
      KeycloakTenantAuthenticationModule.forRoot({
        deepLinksConfig: {
          deepLinkingScheme:
            (environment as EnvironmentConfig)?.deepLinkingScheme ||
            ThemeUrls.IMMOMIO
        },
        keycloakConfig: {
          jsonConfig: () => environment.keycloakConfig
        }
      })
    ),
    DateTimeService,
    FreshchatWidgetService,
    AdblockDetectorService,
    AuthTokenService,
    UserConversionService,
    FormStorageService,
    BodyService,
    NewRelicService,
    provideStore(appReducers),
    provideEffects(effects),
    !environment.production
      ? provideStoreDevtools({ connectInZone: true })
      : [],
    provideRouterStore({
      serializer: CustomSerializer
    }),
    provideNgxMask()
  ]
}).catch(err => console.error(err));
