import { Component, inject, input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Person } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from '@ui/legacy-lib';

@Component({
  selector: 'app-contract-help-modal',
  templateUrl: './contract-help-modal.component.html',
  styleUrls: ['./contract-help-modal.component.scss'],

  imports: [ComponentsModule, TranslateModule]
})
export class ContractHelpModalComponent {
  private ngbActiveModal = inject(NgbActiveModal);

  public contact = input<Person>();
  public customCookieSettings = input<() => void>(() => {
    // Placeholder
  });

  public onCustomCookieSettings() {
    this.customCookieSettings()();
    this.dismiss();
  }

  public dismiss() {
    this.ngbActiveModal.dismiss();
  }

  public ok() {
    this.ngbActiveModal.close();
  }
}
