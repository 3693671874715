import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Store } from '@ngrx/store';

import { Go } from '@ui/legacy-lib';
import { MainPageNavigation } from '../../../../config';
import { CacheIntent } from '../../../../+state';

@Component({
  selector: 'app-confirm-intent',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmIntentComponent implements OnInit {
  private store = inject(Store);
  private route = inject(ActivatedRoute);

  ngOnInit() {
    const applicationId = this.route.snapshot.params.applicationId || '';
    this.store.dispatch(CacheIntent({ applicationId }));
    this.store.dispatch(
      new Go({
        path: [MainPageNavigation.EXPOSE, applicationId],
        query: {
          origin: `${MainPageNavigation.PROPERTIES}/${MainPageNavigation.APPLICATIONS}`
        }
      })
    );
  }
}
