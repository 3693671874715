import { Component, inject, input, output } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import {
  AddressPipe,
  ComponentsModule,
  DateTimePipe,
  DownloadService,
  FullNamePipe,
  Go,
  HumanizeBooleanPipe,
  WINDOW_REF
} from '@ui/legacy-lib';

import {
  Attachment,
  PropertySearcherUser,
  SearchProfile
} from '@ui/shared/models';

import { CurrencyPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ENVIRONMENT_CONFIG } from '../../core';
import { GuestFormValues } from '../../auth/models';

@UntilDestroy()
@Component({
  selector: 'app-profile-summary',
  templateUrl: './profile-summary.component.html',
  styleUrls: ['./profile-summary.component.scss'],

  imports: [
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    TranslateModule,
    CurrencyPipe,
    AddressPipe,
    DateTimePipe,
    FullNamePipe,
    HumanizeBooleanPipe
  ]
})
export class ProfileSummaryComponent {
  readonly form = input<FormGroup>(undefined);
  readonly userData = input<PropertySearcherUser>(undefined);
  readonly guestMode = input(false);
  readonly searchProfiles = input<SearchProfile[]>(undefined);
  readonly schufaCard = input<boolean>(undefined);
  readonly isRegister = input<boolean>(undefined);
  readonly editSearchProfile = output<SearchProfile>();
  readonly deleteSearchProfile = output<string>();
  readonly toggleSearchProfile = output<string>();
  readonly addSearchProfile = output();
  private sanitizer = inject(DomSanitizer);
  private downloadService = inject(DownloadService);
  private store = inject(Store);
  private activatedRoute = inject(ActivatedRoute);
  private environment = inject(ENVIRONMENT_CONFIG);
  private windowRef = inject(WINDOW_REF);

  public get formValue() {
    return this.form().value as GuestFormValues;
  }

  public get checkApplication() {
    return this.formValue.checkApplication;
  }

  public get dkZero() {
    return this.formValue.dkZero;
  }

  public get dkOne() {
    return this.formValue.dkOne;
  }

  public get dkTwo() {
    return this.formValue.dkTwo;
  }

  public get dkThree() {
    return this.formValue.dkThree;
  }

  public get hasAnyDocument() {
    return (
      this.hasWbsDocument ||
      this.hasCreditReportDocument ||
      this.hasIncomeProofDocument
    );
  }

  public get hasWbsDocument() {
    return !!this.dkZero.wbsDocument;
  }

  public get hasCreditReportDocument() {
    return !!this.dkThree.creditScore;
  }

  public get hasIncomeProofDocument() {
    return !!this.dkThree.incomeProof;
  }

  public get hasOtherDocuments() {
    return this.dkThree.otherDocuments?.length;
  }

  public get isNewFile() {
    return this.dkOne.portrait.file instanceof Blob;
  }

  public get imgSrc() {
    if (!this.dkOne.portrait) return '';

    return this.isNewFile
      ? this.getSafeUrl(this.dkOne.portrait.file)
      : this.dkOne.portrait.url;
  }

  public get userEmail() {
    return this.userData()?.email || this.checkApplication.email;
  }

  public downloadAttachment(file: Attachment) {
    if (this.guestMode()) {
      // todo replace gql-service paths with enums from a common file
      this.downloadService
        .downloadFileFromEndpoint('/guest/download', {
          token: this.activatedRoute.snapshot.queryParams.token,
          url: file.url,
          filename: file.name,
          encrypted: file.encrypted
        })
        .pipe(untilDestroyed(this))
        .subscribe((blob: Blob) => {
          this.downloadService.download(blob, file.name + file.extension);
        });
    } else {
      this.downloadService.downloadByUrl(file);
    }
  }

  public onEditSearchProfile(searchProfile: SearchProfile) {
    this.editSearchProfile.emit(searchProfile);
  }

  public onDeleteSearchProfile(id: string) {
    this.deleteSearchProfile.emit(id);
  }

  public onToggleSearchProfile(id: string) {
    this.toggleSearchProfile.emit(id);
  }

  public onAddSearchProfile() {
    this.addSearchProfile.emit();
  }

  public onSchufaRegister() {
    if (this.isRegister()) {
      // during registration, we don't want the user to loose already entered data,
      // so we open SCHUFA in new tab.
      const url = `${this.environment.property_searcher_base_url}/de/schufa`;
      this.windowRef.open(url, '_blank');
    } else {
      this.store.dispatch(
        new Go({
          path: ['affiliate-partner', 'schufa', 'personalInformation']
        })
      );
    }
  }

  private getSafeUrl(file: Blob) {
    return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
  }
}
