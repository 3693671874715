import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { Go, ShowError } from '@ui/legacy-lib';
import { SchufaFacade } from '../../core';
import { MainPageNavigation, notificationConfig } from '../../config';
import { SchufaStepsRoutes } from '../../components/schufa/schufa-step.routes';
import { SchufaErrorStructure } from '../../models';
import {
  NEXT_STEP,
  NextStep,
  SEND_SCHUFA_DELIVERY,
  SEND_SCHUFA_ORDER,
  SEND_SCHUFA_PAYMENT,
  SEND_SCHUFA_PERSONAL_DATA,
  SendSchufaDelivery,
  SendSchufaDeliveryFail,
  SendSchufaDeliverySuccess,
  SendSchufaOrder,
  SendSchufaOrderFail,
  SendSchufaOrderSuccess,
  SendSchufaPayment,
  SendSchufaPaymentFail,
  SendSchufaPaymentSuccess,
  SendSchufaPersonalData,
  SendSchufaPersonalDataFail,
  SendSchufaPersonalDataSuccess
} from './schufa.actions';
import {
  getCurrentStepNumber,
  getSchufaOrderResponseData,
  getSchufaPaymentResponseData,
  getSchufaPersonalDataResponseData
} from './schufa.selectors';

@Injectable()
export class SchufaEffects {
  private actions$ = inject(Actions);
  private store = inject(Store);
  private schufaFacade = inject(SchufaFacade);

  changeStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType<NextStep>(NEXT_STEP),
      map(action => action.userData),
      withLatestFrom(this.store.select(getCurrentStepNumber)),
      map(([userData, currentStepNumber]) => {
        return new Go({
          path: [
            userData
              ? `${MainPageNavigation.SERVICE_CENTER}/${MainPageNavigation.SCHUFA}`
              : MainPageNavigation.SCHUFA,
            SchufaStepsRoutes[currentStepNumber - 1].path
          ]
        });
      })
    )
  );

  schufaPersonalData$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SendSchufaPersonalData>(SEND_SCHUFA_PERSONAL_DATA),
      map(action => action.personalInformation),
      switchMap(personalInforamtion =>
        this.schufaFacade.personalData(personalInforamtion).pipe(
          map(response => new SendSchufaPersonalDataSuccess(response)),
          catchError((error: SchufaErrorStructure) => {
            return [
              new SendSchufaPersonalDataFail(error.graphQLErrors[0].extra)
            ];
          })
        )
      )
    )
  );

  schufaPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SendSchufaPayment>(SEND_SCHUFA_PAYMENT),
      map(action => action.payment),
      withLatestFrom(this.store.select(getSchufaPersonalDataResponseData)),
      switchMap(([payment, order]) =>
        this.schufaFacade.payment(payment, order.orderNumber).pipe(
          map(response => new SendSchufaPaymentSuccess(response)),
          catchError((error: SchufaErrorStructure) => {
            return [new SendSchufaPaymentFail(error.graphQLErrors[0].extra)];
          })
        )
      )
    )
  );

  schufaOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SendSchufaOrder>(SEND_SCHUFA_ORDER),
      withLatestFrom(this.store.select(getSchufaPaymentResponseData)),
      switchMap(([_, order]) =>
        this.schufaFacade.order(order.orderNumber).pipe(
          map(response => new SendSchufaOrderSuccess(response)),
          catchError((error: SchufaErrorStructure) => {
            return [new SendSchufaOrderFail(error.graphQLErrors[0].extra)];
          })
        )
      )
    )
  );

  schufaDelivery$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SendSchufaDelivery>(SEND_SCHUFA_DELIVERY),
      map(action => action.delivery),
      withLatestFrom(this.store.select(getSchufaOrderResponseData)),
      switchMap(([delivery, order]) =>
        this.schufaFacade.delivery(delivery, order.deliveryNumber).pipe(
          map(response => new SendSchufaDeliverySuccess(response)),
          catchError((error: SchufaErrorStructure) => {
            return [
              new SendSchufaDeliveryFail(error.graphQLErrors[0]?.extra),
              new ShowError(notificationConfig.schufa.delivery.error)
            ];
          })
        )
      )
    )
  );
}
