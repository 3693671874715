import { Component, inject, input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from '@ui/legacy-lib';

@Component({
  selector: 'app-apply-confirmation',
  templateUrl: './apply-confirmation.component.html',
  styleUrls: ['./apply-confirmation.component.scss'],

  imports: [ComponentsModule, TranslateModule]
})
export class ApplyConfirmationComponent {
  private ngbActiveModal = inject(NgbActiveModal);

  readonly name = input<string>(undefined);

  close() {
    this.ngbActiveModal.close();
  }

  dismiss() {
    this.ngbActiveModal.dismiss();
  }
}
