import { inject, Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';

import { map } from 'rxjs/operators';
import { WINDOW_REF } from '@ui/legacy-lib';

import { tenantRegisterMutation } from '../queries';
import { ENVIRONMENT_CONFIG } from '../environment';
import { AuthErrorStructure, UserData, UserRegisterToken } from '../../models';

export const ERRORS_MAPPING = {
  'errors.emailalreadyinuse': 'EMAIL_IN_USE_M',
  default: 'UNKNOWN_SERVER_ERROR_M'
};

@Injectable()
export class AuthService {
  private apollo = inject(Apollo);
  private windowRef = inject(WINDOW_REF);
  private environment = inject(ENVIRONMENT_CONFIG);

  public register(tenantRegisterInput: UserData, customerId?: string) {
    return this.apollo
      .mutate({
        mutation: tenantRegisterMutation,
        variables: { tenantRegisterInput, customerId }
      })
      .pipe(
        map(
          (result: ApolloQueryResult<UserRegisterToken>) =>
            result?.data?.tenantRegister
        )
      );
  }

  public getAuthErrorReadable(dataContainingError: AuthErrorStructure) {
    let message = '';
    if (dataContainingError.graphQLErrors) {
      message = this.getNestedError(dataContainingError.graphQLErrors[0]);
    }

    if (dataContainingError.errors) {
      message = this.getNestedError(dataContainingError.errors[0]);
    }

    message = message.toString().toLowerCase();

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return ERRORS_MAPPING[message] || ERRORS_MAPPING.default;
  }

  private getNestedError(error) {
    if (error?.extra[0]) {
      if (Array.isArray(error.extra[0])) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return error.extra[0][0].defaultMessage;
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return error.extra[0].message;
    }

    return 'default';
  }
}
