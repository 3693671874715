import {
  Component,
  forwardRef,
  OnInit,
  inject,
  input,
  output
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseStep } from '@ui/legacy-lib';
import { GERMAN_COUNTRY_CODE, GERMAN_COUNTRY_NAME } from '@ui/legacy-lib';
import { Address, NameValue } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { NgTemplateOutlet } from '@angular/common';
import { ComponentsModule } from '@ui/legacy-lib';

@UntilDestroy()
@Component({
  selector: 'app-moving-auction-address',
  templateUrl: './moving-auction-address.component.html',
  styleUrls: ['./moving-auction-address.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MovingAuctionAddressComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MovingAuctionAddressComponent),
      multi: true
    }
  ],

  imports: [
    NgTemplateOutlet,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    SvgIconComponent,
    TranslateModule
  ]
})
export class MovingAuctionAddressComponent
  extends BaseStep
  implements OnInit, ControlValueAccessor
{
  private fb = inject(FormBuilder);

  readonly rentedObjects = input<NameValue<Address>[]>(undefined);
  readonly selectedAddressChange = output<Address>();

  public formGroup: FormGroup;

  private onChange: (args) => any = () => null;
  private onTouch: () => any = () => null;

  public get addressFormConfig() {
    return { countryName: false };
  }

  public get currentAddressForm() {
    return this.formGroup.get('currentAddress');
  }

  public get newAddressForm() {
    return this.formGroup.get('newAddress');
  }

  public get hasMultipleRentedObjects() {
    return this.rentedObjects()?.length > 1;
  }

  private createCurrentAddressFormGroup() {
    return this.fb.group({
      city: [null, [Validators.maxLength(25), Validators.required]],
      zipCode: [
        null,
        [Validators.minLength(5), Validators.maxLength(5), Validators.required]
      ],
      street: [null, [Validators.maxLength(30), Validators.required]],
      houseNumber: [null, [Validators.maxLength(10), Validators.required]],
      country: [GERMAN_COUNTRY_CODE],
      countryName: [GERMAN_COUNTRY_NAME],
      district: [null],
      region: ['']
    });
  }

  private createNewAddressFormGroup() {
    return this.fb.group({
      city: [null, [Validators.maxLength(25), Validators.required]],
      zipCode: [null, [Validators.minLength(5), Validators.maxLength(5)]],
      street: [null, Validators.maxLength(30)],
      houseNumber: [null, Validators.maxLength(10)],
      country: [GERMAN_COUNTRY_CODE],
      countryName: [GERMAN_COUNTRY_NAME],
      district: [null],
      region: ['']
    });
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.formGroup = this.fb.group({
      currentAddress: this.createCurrentAddressFormGroup(),
      newAddress: this.createNewAddressFormGroup(),
      selectAddress: []
    });

    this.formGroup
      .get('selectAddress')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((address: Address) => {
        this.newAddressForm.patchValue(address);
        this.selectedAddressChange.emit(address);
      });

    this.formGroup.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      this.onChange(value);
      this.onTouch();
    });
  }

  public validate() {
    return this.formGroup.valid ? null : { missingFields: true };
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public writeValue(obj: any): void {
    this.formGroup.patchValue(obj);
  }

  protected onCheckForm() {
    this.formGroup.valid ? this.onSubmit() : this.formGroup.markAllAsTouched();
  }
}
