import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input
} from '@angular/core';
import { ThemeService } from '@ui/legacy-lib';

import { TranslateModule } from '@ngx-translate/core';
import { UrlUtilService } from '../../core';

@Component({
  selector: 'app-customer-info-legacy',
  templateUrl: './customer-info-legacy.component.html',
  styleUrls: ['./customer-info-legacy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

  imports: [TranslateModule]
})
export class CustomerInfoLegacyComponent {
  readonly name = input<string>(undefined);
  readonly logo = input<string>(undefined);
  readonly logoRedirectUrl = input<string>(undefined);
  readonly nameHeading = input<string>(undefined);
  readonly showOfferFrom = input(true);
  readonly showInCooperationWith = input(true);
  private themeService = inject(ThemeService);
  private urlHelperService = inject(UrlUtilService);

  public get showInCooperationWithImmomio() {
    return this.logo();
  }

  public get customerLogo() {
    return (
      this.logo() ||
      this.themeService?.getTheme(this.themeService?.getDefaultTheme)?.logo?.url
    );
  }

  public get getLogoRedirectUrl() {
    return this.urlHelperService.getLogoRedirectUrl(this.logoRedirectUrl());
  }
}
