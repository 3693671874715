import { OnInit, Directive, input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { WizardStep } from '@ui/legacy-lib';
import { Step } from '@ui/legacy-lib';

// noinspection AngularMissingOrInvalidDeclarationInModule
@Directive({})
export class BaseSchufaStep extends WizardStep implements OnInit {
  readonly form = input<FormGroup>(undefined);
  readonly stepIndex = input<number>(undefined);
  readonly steps = input<Step[]>(undefined);

  public nextStep() {
    const stepName = this.steps()[this.stepIndex()].name;
    this.onNextStep.emit(stepName);
  }

  public onSubmit() {
    const form = this.form();
    form.valid ? this.nextStep() : form.markAllAsTouched();
  }
}
