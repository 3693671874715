import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ActionState, ButtonComponent, Go } from '@ui/legacy-lib';

import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import {
  getSagaDataTransferActionState,
  SagaDataTransfer
} from '../../../../+state';

@Component({
  selector: 'app-saga-transfer-data',
  templateUrl: './saga-transfer-data.component.html',
  styleUrls: ['./saga-transfer-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, NgTemplateOutlet, AsyncPipe, ButtonComponent]
})
export class SagaTransferDataComponent implements OnInit {
  public dataTransferActionState: Observable<ActionState>;
  public token: string;
  public isAccepted: boolean;
  private store = inject(Store);
  private route = inject(ActivatedRoute);

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParams.token || '';
    if (!this.token) {
      this.store.dispatch(new Go({ path: ['/login'] }));
    }
    this.dataTransferActionState = this.store.select(
      getSagaDataTransferActionState
    );
  }

  public transfer(accept: boolean) {
    this.isAccepted = accept;
    this.store.dispatch(new SagaDataTransfer(accept, this.token));
  }
}
