import { Component, inject, input, OnInit } from '@angular/core';

import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { filter, map, take } from 'rxjs/operators';

import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';

import {
  Constants,
  PropertyMatchBean,
  PropertySearcherUser,
  SelfDisclosureModel,
  SelfDisclosureQuestion,
  SelfDisclosureQuestionType
} from '@ui/shared/models';

import {
  ButtonComponent,
  getConstants,
  LocalStorageService,
  ModalContentComponent,
  ModalFooterComponent,
  ModalV2Component,
  SelfDisclosureFormComponent
} from '@ui/legacy-lib';

import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
  DeclareIntent,
  getSaveSelfDisclosureAnswersActionState,
  getUserData,
  SaveSelfDisclosureAnswers,
  SelfDisclosureState
} from '../../../+state';
import { storageKeys } from '../../../config';

@UntilDestroy()
@Component({
  selector: 'app-self-disclosure-modal',
  templateUrl: './self-disclosure-modal.component.html',
  styleUrls: ['./self-disclosure-modal.component.scss'],

  imports: [
    SelfDisclosureFormComponent,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    AsyncPipe,
    ModalV2Component,
    ModalContentComponent,
    ModalFooterComponent,
    ButtonComponent
  ]
})
export class SelfDisclosureModalComponent implements OnInit {
  public isProcessing$: Observable<boolean>;
  public answeredSelfDisclosure: SelfDisclosureModel;
  public selfDisclosureModel = input<SelfDisclosureModel>(undefined);
  public selfDisclosureResponses = input<SelfDisclosureModel>(undefined);
  public propertyMatch = input<PropertyMatchBean>();
  public userData$: Observable<PropertySearcherUser>;
  public constants$: Observable<Constants>;
  public control = new FormControl();
  public formValiditySubject = new Subject<boolean>();
  public mandatoryFieldsMissing: boolean;
  public hasIntent = input<boolean>();
  private ngbActiveModal = inject(NgbActiveModal);
  private store = inject<Store<SelfDisclosureState>>(Store);
  private localStorageService = inject(LocalStorageService);

  get mandatoryFieldsHasAnswer() {
    return this.answeredSelfDisclosure.questions.every(question => {
      const foundQuestion = (
        this.control.value as SelfDisclosureModel
      ).questions.find(q => q.id === question.id);
      const answer = foundQuestion?.answer ?? null;
      return (
        !question.mandatory ||
        foundQuestion.answerUnavailable ||
        (!!answer && answer.length > 0) ||
        (!!answer && Object.keys(answer).length > 0) ||
        (question.type === SelfDisclosureQuestionType.BOOLEAN &&
          (answer || answer === false))
      );
    });
  }

  public ngOnInit() {
    this.localStorageService.setItem(
      storageKeys.selfDisclosureApplicationId,
      this.propertyMatch().id
    );
    this.isProcessing$ = this.store
      .select(getSaveSelfDisclosureAnswersActionState)
      .pipe(map(state => state.pending));

    this.constants$ = this.store.select(getConstants);

    this.answeredSelfDisclosure = {
      ...this.selfDisclosureModel(),
      // filter questions that are hidden (hidden flag is mapped in graphQL to showSelfDisclosureQuestions)
      questions: this.selfDisclosureModel().questions
        ? this.selfDisclosureModel().questions.filter(
            q => q.showSelfDisclosureQuestions
          )
        : [],
      confirmations: this.selfDisclosureModel().confirmations || [],
      documents: this.selfDisclosureModel().documents || [],
      description: this.selfDisclosureModel().description || '',
      feedbackEmail: this.selfDisclosureModel().feedbackEmail || '',
      propertySearcherComment:
        this.selfDisclosureModel().propertySearcherComment || ''
    };

    this.userData$ = this.store.select(getUserData).pipe(
      filter(data => !!data),
      take(1)
    );

    if (
      this.selfDisclosureResponses() &&
      this.selfDisclosureResponses().questions
    ) {
      this.answeredSelfDisclosure.isAnswered = true;
      this.answeredSelfDisclosure.propertySearcherComment =
        this.selfDisclosureResponses().propertySearcherComment;
      this.answeredSelfDisclosure.uploadedDocuments =
        this.selfDisclosureResponses().uploadedDocuments;
      this.answeredSelfDisclosure.questions =
        this.answeredSelfDisclosure.questions.map(question => {
          return this.getAnsweredQuestion(
            question,
            this.selfDisclosureResponses().questions
          );
        });
    }
    this.control.patchValue(this.answeredSelfDisclosure);
    this.formValiditySubject
      .asObservable()
      .pipe(untilDestroyed(this))
      .subscribe((isValid: boolean) => {
        this.mandatoryFieldsMissing = isValid;
      });
  }

  public onFormValidityChange(isValid: boolean) {
    this.control.setErrors(isValid ? null : { missingFields: true });
  }

  public dismiss() {
    this.localStorageService.removeItem(
      storageKeys.selfDisclosureApplicationId
    );
    this.ngbActiveModal.dismiss();
  }

  public save() {
    if (!this.mandatoryFieldsHasAnswer || !this.control.valid) {
      this.formValiditySubject.next(true);
      return;
    }
    this.formValiditySubject.next(false);
    this.formValiditySubject.complete();

    const value = {
      ...this.control.value,
      confirmations: this.selfDisclosureModel().confirmations,
      documents: this.selfDisclosureModel().documents
    };

    this.store.dispatch(
      new SaveSelfDisclosureAnswers(this.propertyMatch().property.id, value)
    );

    this.control.setErrors(null);

    this.store
      .select(getSaveSelfDisclosureAnswersActionState)
      .pipe(
        map(state => state.done),
        filter(done => !!done),
        take(1)
      )
      .subscribe(() => {
        if (this.hasIntent()) {
          this.store.dispatch(
            DeclareIntent({
              declareIntentData: {
                intent: true,
                propertySearcherId: this.propertyMatch().id
              }
            })
          );
        }
        this.localStorageService.removeItem(
          storageKeys.selfDisclosureApplicationId
        );
        this.ngbActiveModal.close();
      });
  }

  private getAnsweredQuestion(
    question: SelfDisclosureQuestion,
    responses: SelfDisclosureQuestion[]
  ) {
    const answer = this.findQuestionByTitle(responses, question.title);
    return answer ? this.answerQuestion(question, answer) : question;
  }

  private answerQuestion(
    question: SelfDisclosureQuestion,
    answer: SelfDisclosureQuestion
  ) {
    const answeredQuestion = this.addAnswerToQuestion(question, answer);
    if (answeredQuestion.subQuestions && answer.subQuestions) {
      answeredQuestion.subQuestions = answeredQuestion.subQuestions.map(
        subQuestion => {
          const answerSubQuestion = this.findQuestionByTitle(
            answer.subQuestions,
            subQuestion.title
          );
          if (answerSubQuestion) {
            return this.addAnswerToQuestion(subQuestion, answerSubQuestion);
          }
          return subQuestion;
        }
      );
    }
    return answeredQuestion;
  }

  private addAnswerToQuestion(
    question: SelfDisclosureQuestion,
    answer: SelfDisclosureQuestion
  ) {
    return {
      ...question,
      // we need to account for value 'false' of questions type BOOLEAN
      answer:
        answer.answer || answer.answer === false
          ? answer.answer
          : this.getDefaultAnswer(question),
      comment: answer.comment,
      upload: answer.upload,
      answerUnavailable: answer.answerUnavailable,
      confirmations: answer.confirmations
    };
  }

  private getDefaultAnswer(question: SelfDisclosureQuestion) {
    if (
      question.type === SelfDisclosureQuestionType.PERSONS ||
      question.type === SelfDisclosureQuestionType.CHILDREN
    ) {
      return [];
    } else if (
      question.type === SelfDisclosureQuestionType.BOOLEAN ||
      question.type === SelfDisclosureQuestionType.IBAN ||
      question.type === SelfDisclosureQuestionType.TAX_ID
    ) {
      /*
       * This MUST be null, or the PDF download breaks. BE parses for boolean and e.g. an empty
       * object will result in a parsing error:
       * https://app.asana.com/0/771015745353211/1146597974985705
       *
       * Similarly for IBAN and Tax ID, if this isn't null, you get "[object Object]" as the default input value
       */
      return null;
    }
    return {};
  }

  private findQuestionByTitle(array: SelfDisclosureQuestion[], title: string) {
    return array.find(question => question.title === title);
  }
}
