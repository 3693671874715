import { gql } from 'apollo-angular';
import { ResponseStatus } from '@ui/shared/models';
import { PropertyMatches } from '../../models';

export interface DenyProposalMutationResponse {
  denyProposal: ResponseStatus;
}

export const denyProposalMutation = gql`
  mutation denyProposal($reasonData: ReasonInput!) {
    denyProposal(input: $reasonData) {
      status
    }
  }
`;

export interface AcceptProposalMutationResponse {
  acceptProposal: {
    id: string;
  };
}

export const acceptProposalMutation = gql`
  mutation acceptProposal($id: ID!) {
    acceptProposal(id: $id) {
      id
    }
  }
`;

export interface ProposalsResult {
  proposals: PropertyMatches;
}
