import { Component, inject, OnInit, input, output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import {
  ActionState,
  ComponentsModule,
  DateTimePipe,
  fadeOnEnterLeaveAnimation,
  getConstants,
  isValueNotNullAndUndefined,
  LocalStorageService,
  ModalService
} from '@ui/legacy-lib';

import {
  ApplicantStatus,
  Appointment,
  AppointmentAcceptanceCancelReason,
  AppointmentAcceptanceShortInfo,
  AppointmentAcceptanceStates,
  AppointmentInvitationState,
  AppointmentsBundles,
  AppointmentSelection,
  NameValue,
  PropertyBean,
  PropertyMatchBean,
  PropertySearcherUser
} from '@ui/shared/models';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { IntentButtonsComponent } from '../intent-buttons/intent-buttons.component';
import {
  getAppointmentsActionState,
  getAppointmentsBundleById,
  getAppointmentsItemActionState,
  getProcessAnonymousApplicationActionState,
  getProcessApplicationActionState,
  getProfileCompleteness,
  getUserData,
  getUserDataLoading,
  guestApplyDone,
  guestApplyLoading,
  NoAppointmentFitting,
  SelectAppointment,
  SwitchAppointment
} from '../../+state';
import { mixinPropertiesActions } from '../../screens/objects/components/properties/mixin';
import { mixinAppointmentsActions } from '../../screens/objects/components/properties/components/appointments/components';

class ApplicationActionsBase {}

const _PropertiesComponentMixinBase = mixinPropertiesActions(
  ApplicationActionsBase
);

const _ApplicationActionsComponentMixinBase = mixinAppointmentsActions(
  _PropertiesComponentMixinBase
);

@UntilDestroy()
@Component({
  selector: 'app-application-actions',
  templateUrl: './application-actions.component.html',
  styleUrls: ['./application-actions.component.scss'],
  animations: [fadeOnEnterLeaveAnimation],

  imports: [
    ComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    SvgIconComponent,
    IntentButtonsComponent,
    TranslateModule,
    AsyncPipe,
    DateTimePipe
  ]
})
export class ApplicationActionsComponent
  extends _ApplicationActionsComponentMixinBase
  implements OnInit
{
  readonly propertyMatch = input<PropertyMatchBean>(undefined);
  readonly property = input<PropertyBean>(undefined);
  readonly disabled = input<boolean>(undefined);
  readonly isApplyPage = input<boolean>(undefined);
  readonly salesLegalTextCheckboxesValid = input<boolean>(undefined);
  readonly landingApply = output();
  readonly applyAnonymous = output();
  readonly openSalesModal = output();
  public profileComplete$: Observable<boolean>;
  public profileCompleteness$: Observable<number>;
  public appointmentsActionState$: Observable<ActionState>;
  public appointmentsItemActionState$: Observable<ActionState>;
  public applyPageProcessing$: Observable<boolean>;
  public applicationDone$: Observable<boolean>;
  public salesGuestApplyDone$: Observable<boolean>;
  public showCancelAppointment = false;
  public showChooseAppointment = false;
  public showViaEmailForm = false;
  public appointmentAcceptanceCancelReasonTypes: NameValue[];
  public reasonTypeModel: string;
  public otherReasonText: string;
  public userData: PropertySearcherUser;
  public appointmentsBundle: AppointmentsBundles;
  public appointments: Appointment[];
  public exclusiveAppointments: Appointment[];
  public newActiveAppointmentId: FormControl;
  public anonymousApplyForm: FormGroup;
  public applicantStatus = ApplicantStatus;
  public queryParams: { [key: string]: string };
  public activeAppointment: Appointment;
  public activeAppointmentAcceptance: AppointmentAcceptanceShortInfo;
  public appointmentState = AppointmentInvitationState;
  private store = inject(Store);
  private modalService = inject(ModalService);
  private localStorageService = inject(LocalStorageService);
  private fb = inject(FormBuilder);

  public get buttonType() {
    return (
      (this.propertyMatch()?.status as ApplicantStatus) ||
      ApplicantStatus.NEW_MATCH
    );
  }

  public get newPropertyMatch() {
    return (
      this.buttonType === this.applicantStatus.NEW_MATCH ||
      this.buttonType === this.applicantStatus.AUTO_OFFERED ||
      this.buttonType === this.applicantStatus.OFFERED
    );
  }

  public get waitForLandlord() {
    return (
      this.buttonType === this.applicantStatus.WAITING_FOR_LANDLORD ||
      this.buttonType === this.applicantStatus.INTENT
    );
  }

  public get showContent() {
    return (
      this.showCancelAppointment ||
      this.showChooseAppointment ||
      this.showViaEmailForm ||
      !!this.propertyMatch()?.upcomingAppointmentDate
    );
  }

  public getActiveAppointment(): Appointment {
    if (this.appointmentsBundle?.appointmentAcceptances?.length === 0) return;
    this.activeAppointment = this.appointmentsBundle?.appointments
      .filter(
        appointment => appointment.state === AppointmentInvitationState.ACTIVE
      )
      .find(appointment => {
        this.activeAppointmentAcceptance =
          this.appointmentsBundle?.appointmentAcceptances
            .filter(
              appointmentAcceptances =>
                appointmentAcceptances.state ===
                AppointmentInvitationState.ACTIVE
            )
            .find(appointmentAcceptances => {
              return appointmentAcceptances.appointmentId === appointment.id;
            });
        return !!this.activeAppointmentAcceptance;
      });
  }

  public ngOnInit() {
    this.newActiveAppointmentId = new FormControl('');

    this.store
      .select(getConstants)
      .pipe(
        filter(constants => !!constants),
        untilDestroyed(this)
      )
      .subscribe(
        constants =>
          (this.appointmentAcceptanceCancelReasonTypes =
            constants.appointmentAcceptanceCancelReasonTypes)
      );

    this.store
      .select(getUserData)
      .pipe(untilDestroyed(this))
      .subscribe(userData => (this.userData = userData));

    this.appointmentsActionState$ = this.store.select(
      getAppointmentsActionState
    );
    this.appointmentsItemActionState$ = this.store.select(
      getAppointmentsItemActionState
    );

    this.appointmentsActionState$
      .pipe(
        filter(state => !state.pending),
        switchMap(() =>
          this.store
            .select(getAppointmentsBundleById, {
              id: this.propertyMatch()?.id
            })
            .pipe(filter(data => isValueNotNullAndUndefined(data)))
        ),
        untilDestroyed(this)
      )
      .subscribe(data => {
        const { appointments, exclusiveAppointments } =
          this.getAppointmentsData(data);
        this.appointmentsBundle = data;
        this.appointments = appointments;
        this.exclusiveAppointments = exclusiveAppointments;
        this.getActiveAppointment();
      });

    this.profileCompleteness$ = this.store.select(getProfileCompleteness);

    this.profileComplete$ = this.profileCompleteness$.pipe(
      map(completeness => completeness === 100)
    );

    this.applicationDone$ = this.store
      .select(getProcessAnonymousApplicationActionState)
      .pipe(map(state => state.done));

    this.anonymousApplyForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      firstname: ['', Validators.required],
      name: ['', Validators.required],
      phone: ['']
    });

    this.applyPageProcessing$ = combineLatest([
      this.store.select(getProcessApplicationActionState),
      this.store.select(getProcessAnonymousApplicationActionState),
      this.store.select(getUserDataLoading),
      this.store.select(guestApplyLoading)
    ]).pipe(
      map(
        ([processing, processingAnon, loading, guestApplyLoading]) =>
          processing.pending ||
          processingAnon.pending ||
          loading ||
          guestApplyLoading
      )
    );

    this.salesGuestApplyDone$ = this.store.select(guestApplyDone);
  }

  public showAppointments() {
    this.appointmentsActionState$
      .pipe(
        filter(state => !state.pending),
        switchMap(() =>
          this.store
            .select(getAppointmentsBundleById, {
              id: this.propertyMatch()?.id
            })
            .pipe(filter(data => isValueNotNullAndUndefined(data)))
        ),
        take(1)
      )
      .subscribe(_data => {
        this.showChooseAppointment = true;
      });
  }

  public onSelectAppointment() {
    if (this.newActiveAppointmentId.value === 'non_fitting') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      this.newActiveAppointmentId.patchValue('');
      this.showChooseAppointment = false;
      return this.store.dispatch(
        new NoAppointmentFitting({
          applicationId: this.propertyMatch().id,
          reasonType: AppointmentAcceptanceCancelReason.APPOINTMENT_DOES_NOT_FIT
        })
      );
    }

    const appointmentAcceptanceId =
      this.appointmentsBundle?.appointmentAcceptances?.find(
        aA => aA?.state === AppointmentAcceptanceStates.ACTIVE
      )?.id;

    const appointment = [
      ...this.exclusiveAppointments,
      ...this.appointments
    ].find(({ id }) => id === this.newActiveAppointmentId.value);

    const result = {
      applicationId: this.propertyMatch()?.id,
      appointmentAcceptanceId,
      appointment,
      property: this.property()
    } as AppointmentSelection;

    const action = appointmentAcceptanceId
      ? new SwitchAppointment(result)
      : new SelectAppointment(result);
    this.store.dispatch(action);
    this.appointmentsItemActionState$
      .pipe(
        filter(state => !state.pending),
        take(1)
      )
      .subscribe(() => {
        this.showChooseAppointment = false;
      });
  }

  public setIntent(intent: string) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.onSetIntent({
      propertyMatch: this.propertyMatch(),
      intent
    });
  }

  public showViaEmail() {
    this.showViaEmailForm = true;
  }

  public onLandingApply() {
    this.landingApply.emit();
  }

  public onApplyAnonymous(anonymousApplyForm) {
    this.applyAnonymous.emit(anonymousApplyForm);
  }

  public onOpenSalesModal() {
    this.openSalesModal.emit();
  }
}
