<app-modal-v2>
  <div class="modal-xs">
    <app-modal-content>
      <div class="modal-body">
        <div class="modal-close">
          <img
            src="/assets/images/icons/icon-close.svg"
            (click)="closeModal()"
          />
        </div>
        <app-register-card
          [form]="form"
          [property]="property()"
          [disabled]="registerProcessing$ | async"
          [errorMessage]="registerError$ | async"
          [socialLogin]="socialLogin()"
          [guestLoginRedirectUrl]="'/guest-register'"
          [showLoginAsGuest]="property()?.allowContinueAsGuest"
          (registerEvent)="onRegister()"
          (loginRedirectEvent)="onLoginRedirect()"
          (socialLoginEvent)="onSocialLogin($event)"
          (loginAsGuestEvent)="closeModal()"
        ></app-register-card>
      </div>
    </app-modal-content>
  </div>
</app-modal-v2>
