import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

import { Go, SessionStorageService } from '@ui/legacy-lib';
import { MainPageNavigation, storageKeys } from '../../../../config';

@Component({
  selector: 'app-proposal-offered',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProposalOfferedComponent implements OnInit {
  private store = inject(Store);
  private sessionStorage = inject(SessionStorageService);
  private route = inject(ActivatedRoute);

  ngOnInit() {
    const { applicationId } = this.route.snapshot.queryParams;
    const stateAfterAuth = {
      pathAfterAuth: `/properties/expose/${String(applicationId)}`
    };

    this.sessionStorage.setItem(storageKeys.stateAfterAuth, stateAfterAuth);

    this.store.dispatch(
      new Go({
        path: [MainPageNavigation.LOGIN]
      })
    );
  }
}
