import { Component, inject, OnDestroy, OnInit, input } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { Route } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { Observable } from 'rxjs';
import { filter, map, take, withLatestFrom } from 'rxjs/operators';

import {
  GenderType,
  PropertyMatchBean,
  RentDepositDeliveryOption,
  RentDepositFormData,
  RentDepositLandlordOption,
  RentDepositModel,
  RentDepositPaymentOption
} from '@ui/shared/models';
import { ComponentsModule, Go, ModalService } from '@ui/legacy-lib';

import { AsyncPipe, CurrencyPipe } from '@angular/common';
import { RentalDepositService } from '../../core';
import { RentDepositStatusModalComponent } from '../../screens/rent-deposit/rent-deposit-status-modal/rent-deposit-status-modal.component';
import {
  getCurrentWizardStep,
  getRentDepositInfo,
  getRuvActionState,
  RentDepositWizardGoToStep,
  RentDepositWizardNextStep,
  RentDepositWizardPreviousStep,
  RentDepositWizardReset,
  UseRuvDeposit
} from '../../+state';
import { dialogConfig, MainPageNavigation } from '../../config';
import { RentDepositSummaryComponent } from './rent-deposit-summary/rent-deposit-summary.component';
import { RentDepositPaymentComponent } from './rent-deposit-payment/rent-deposit-payment.component';
import { RentDepositObjectComponent } from './rent-deposit-object/rent-deposit-object.component';
import { RentDepositPersonalInformationComponent } from './rent-deposit-personal-information/rent-deposit-personal-information.component';
import { RentDepositStepRoutes } from './rent-deposit-step.routes';

@UntilDestroy()
@Component({
  selector: 'app-rent-deposit-wizard',
  templateUrl: './rent-deposit-wizard.component.html',
  styleUrls: ['./rent-deposit-wizard.component.scss'],

  imports: [
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    RentDepositPersonalInformationComponent,
    RentDepositObjectComponent,
    RentDepositPaymentComponent,
    RentDepositSummaryComponent,
    AsyncPipe
  ]
})
export class RentDepositWizardComponent implements OnInit, OnDestroy {
  readonly formData = input<RentDepositFormData>(undefined);
  readonly propertyMatchBean = input<PropertyMatchBean>(undefined);
  public allSteps: {
    name: string;
    nameNice: string;
  }[];
  public currentStep$: Observable<number>;
  public processing$: Observable<boolean>;
  public currentForm$: Observable<FormGroup>;
  public form: FormGroup;
  public rentDepositPayload: RentDepositModel;
  public genderTypes = [
    { value: GenderType.MALE, name: 'GENDERTYPES_MALE' },
    { value: GenderType.FEMALE, name: 'GENDERTYPES_FEMALE' },
    { value: GenderType.INTERSEXUAL, name: 'GENDERTYPES_INTERSEXUAL' }
  ];
  public paymentOptions = [
    {
      value: RentDepositPaymentOption.MONTHLY,
      name: 'rent_deposit.payment.option_monthly_l'
    },
    {
      value: RentDepositPaymentOption.YEARLY,
      name: 'rent_deposit.payment.option_yearly_l'
    }
  ];
  public deliveryOptions = [
    {
      value: RentDepositDeliveryOption.LANDLORD,
      name: 'rent_deposit.payment.delivery_option_landlord_l'
    },
    {
      value: RentDepositDeliveryOption.PROPERTY_SEARCHER,
      name: 'rent_deposit.payment.delivery_option_property_search_l'
    }
  ];
  public landlordOptions = [
    {
      value: RentDepositLandlordOption.LEGAL_PERSON,
      name: 'rent_deposit.object.landlord_legal_person_l'
    },
    {
      value: RentDepositLandlordOption.NATURAL_PERSON,
      name: 'rent_deposit.object.landlord_natural_person_l'
    }
  ];
  private store = inject(Store);
  private fb = inject(FormBuilder);
  private rentalDepositService = inject(RentalDepositService);
  private translateService = inject(TranslateService);
  private modalService = inject(ModalService);
  private currencyPipe = inject(CurrencyPipe);

  public get rentDepositInfo() {
    return this.propertyMatchBean()?.rentDepositInfo;
  }

  public ngOnInit(): void {
    this.processing$ = this.store
      .select(getRuvActionState)
      .pipe(map(state => state.pending));
    this.currentStep$ = this.store.select(getCurrentWizardStep);
    this.allSteps = this.getAllSteps();
    this.form = this.fb.group({
      personalInformation: [],
      object: [],
      payment: []
    });

    const formData = this.formData();
    if (formData) {
      this.form.patchValue(formData);
    }

    this.currentForm$ = this.currentStep$.pipe(
      map(
        (currentStep: number) =>
          this.form.get(this.allSteps[currentStep - 1].name) as FormGroup
      )
    );

    this.paymentOptions = this.paymentOptions.map(item => {
      return {
        ...item,
        name: this.translateService.instant(item.name, {
          amount:
            item.value === RentDepositPaymentOption.MONTHLY
              ? this.currencyPipe.transform(
                  this.rentDepositInfo?.ruvData?.monthlyAmount,
                  'EUR'
                )
              : this.currencyPipe.transform(
                  this.rentDepositInfo?.ruvData?.yearlyAmount,
                  'EUR'
                )
        })
      };
    });

    this.store
      .select(getRuvActionState)
      .pipe(
        filter(state => state.done || !!state.error),
        withLatestFrom(this.store.select(getRentDepositInfo)),
        take(1)
      )
      .subscribe(([_, rentDepositInfo]) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        this.modalService.open(RentDepositStatusModalComponent, {
          data: {
            rentDepositInfo,
            rentDepositModel: this.rentDepositPayload
          },
          backdrop: 'static'
        });

        this.store.dispatch(RentDepositWizardReset());

        this.store.dispatch(
          new Go({
            path: [MainPageNavigation.NEW_HOME]
          })
        );
      });
  }

  public ngOnDestroy(): void {
    this.store.dispatch(RentDepositWizardReset());
  }

  public nextStep() {
    this.rentDepositPayload = this.rentalDepositService.convertToPayload(
      this.form.value,
      this.rentDepositInfo
    );

    this.store.dispatch(
      RentDepositWizardNextStep({
        id: this.propertyMatchBean().id
      })
    );
  }

  public completeStep() {
    this.rentDepositPayload = this.rentalDepositService.convertToPayload(
      this.form.value,
      this.rentDepositInfo
    );
    const input = {
      propertyId: this.propertyMatchBean().property.id,
      input: {
        ...this.rentDepositPayload,
        contractData: {
          ...this.rentDepositPayload.contractData,
          isRentalContractUnlimited: true
        },
        bankData: {
          ...this.rentDepositPayload.bankData
        },
        deliveryData: {
          ...this.rentDepositPayload.deliveryData
        }
      }
    };
    this.store.dispatch(UseRuvDeposit(input));
  }

  public onPrivacyModal() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.modalService.openAcknowledgement({
      data: {
        ...dialogConfig.rentDeposit.ruvPrivacyAck,
        innerHTML: true
      }
    });
  }

  public onEdit(stepName: string) {
    const stepNumber =
      this.allSteps.findIndex(step => step.name === stepName) + 1;
    this.store.dispatch(
      RentDepositWizardGoToStep({
        stepNumber,
        id: this.propertyMatchBean().id
      })
    );
  }

  public previousStep() {
    this.store.dispatch(
      RentDepositWizardPreviousStep({
        id: this.propertyMatchBean().id
      })
    );
  }

  private getAllSteps() {
    return RentDepositStepRoutes.map((routeObject: Route) => ({
      name: routeObject.path,
      nameNice: routeObject.data.title
    }));
  }
}
