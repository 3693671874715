import { Component, input, OnInit } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DigitalContract } from '@ui/shared/models';
import { BaseStep, ComponentsModule } from '@ui/legacy-lib';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@UntilDestroy()
@Component({
  selector: 'app-contract-viewing',
  templateUrl: './contract-viewing.component.html',
  styleUrls: ['./contract-viewing.component.scss'],

  imports: [FormsModule, ReactiveFormsModule, ComponentsModule, TranslateModule]
})
export class ContractViewingComponent extends BaseStep implements OnInit {
  readonly continueSigning = input<boolean>(undefined);
  readonly flatVisitedUpdated = input<boolean>(undefined);
  readonly minViewingDate = input<NgbDateStruct>(undefined);
  readonly maxViewingDate = input<NgbDateStruct>(undefined);
  readonly contract = input<DigitalContract>(undefined);

  public booleanOptions = [
    { name: 'general.yes_l', value: true },
    { name: 'general.no_l', value: false }
  ];

  ngOnInit(): void {
    super.ngOnInit();

    if (this.flatVisitedUpdated()) {
      this.form().get('confirmViewing').clearValidators();
      this.form().get('date').clearValidators();
      this.form().get('visited').clearValidators();
    }

    this.form()
      .get('visited')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(hasVisited => {
        if (hasVisited || !this.continueSigning()) {
          this.form().get('confirmViewing').disable();
        } else {
          this.form().get('confirmViewing').enable();
        }

        if (hasVisited) {
          this.form().get('date').enable();
        } else {
          this.form().get('date').disable();
        }
      });
  }

  public get hasVisited() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.form().get('visited').value;
  }

  public get hasNotVisited() {
    return this.form().get('visited').value === false;
  }
}
