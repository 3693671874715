<app-modal-v2>
  <div class="modal-m">
    <app-modal-content>
      <div class="modal-body confirm-iban__body">
        <p class="title-m confirm-iban__title">
          {{ 'digital_contract.sign_wizard.confirm_iban_header_l' | translate }}
        </p>

        <div class="confirm-iban__message">
          <span>{{
            'digital_contract.sign_wizard.confirm_iban_message_l' | translate
          }}</span>
          <span class="uppercase-m semibold">{{ iban() }}</span>
        </div>
      </div>
    </app-modal-content>

    <app-modal-footer>
      <div class="modal-footer confirm-iban__footer">
        <app-button [type]="'light-bordered'" (clickEvent)="cancel()">{{
          'general.no_l' | translate
        }}</app-button>
        <app-button [type]="'primary'" (clickEvent)="apply()">{{
          'general.yes_l' | translate
        }}</app-button>
      </div>
    </app-modal-footer>
  </div>
</app-modal-v2>
