import { inject } from '@angular/core';
import { Store } from '@ngrx/store';

import { ActivatedRouteSnapshot } from '@angular/router';
import { catchError, combineLatest, filter, map, of } from 'rxjs';

import { getRedirectUrl, Go, LogoutReasons, UserLogout } from '@ui/legacy-lib';
import * as fromAppState from '../../+state';
import { ENVIRONMENT_CONFIG } from '../environment';
import { NAVIGATION_LINK } from '../../config';

/**
 * This guard will check if the user is already registered with an
 * account at immomio. An already existing account needs to be
 * "connected" to the current PS user for legal reason.
 *
 * For more information:
 * - ART-5684, PS Login - First login with RS data
 * - ISP-DS-0007: Login Handling for RS Users in PS App
 */
export const AccountsGuard = (activatedRoute: ActivatedRouteSnapshot) => {
  const store: Store<fromAppState.AppState> = inject(Store);
  const environment = inject(ENVIRONMENT_CONFIG);
  const logoutUser = () => {
    store.dispatch(
      new UserLogout(
        getRedirectUrl(environment.property_searcher_base_url, '/login', {
          queryParams: { message: LogoutReasons.USER_DATA_LOAD_FAIL },
          pathAfterAuth: ''
        })
      )
    );
  };
  store.dispatch(new fromAppState.FetchIsRegisteredAtImmomio());

  return combineLatest([
    store.select(fromAppState.getIsRegisteredAtImmomioActionState),
    store.select(fromAppState.getIsRegisteredAtImmomio)
  ]).pipe(
    filter(
      ([actionState, isRegisteredAtImmomio]) =>
        (actionState.done && isRegisteredAtImmomio !== null) ||
        actionState.error !== null
    ),
    map(([actionState, isRegisteredAtImmomio]) => {
      if (actionState.error !== null) {
        logoutUser();
        return false;
      }

      if (!isRegisteredAtImmomio.registeredAtImmomio) {
        store.dispatch(
          new Go({
            path: [NAVIGATION_LINK.TERMS_AND_CONDITIONS],
            query: activatedRoute.queryParams
          })
        );
        return false;
      }

      return true;
    }),
    catchError(() => {
      logoutUser();
      return of(false);
    })
  );
};
