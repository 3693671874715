<app-property-card
  [propertyData]="propertyData"
  [landlordInfoData]="landlordInfoData"
  [pageView]="pageView()"
  [isRented]="isRented"
  [userIsTenant]="applicationStatusConfig?.isTenant"
  [actionNeeded]="actionNeeded"
  [isApplyPage]="isApplyPage()"
  [isProposal]="isProposal(propertyMatch())"
  [isProcessing]="isProcessing"
  [isShowSelfDisclosure]="isShowSelfDisclosure(property())"
  [isNotBlocked]="isNotBlocked"
  [fadeOutCard]="applicationStatusConfig?.hide"
  [hasCustomQuestions]="hasCustomQuestion(propertyMatch())"
  [hasUnansweredQuestions]="propertyMatch()?.hasUnansweredQuestions"
  [hasDeclaredIntention]="isIntent(propertyMatch()?.status)"
  [hasDeniedIntention]="isNoIntent(propertyMatch()?.status)"
  [showAddress]="showAddress"
  [showLandlordInfo]="true"
  [showAvailableFromDate]="false"
  [showExternalId]="pageView()"
  [enableAppointmentSelection]="canChooseAnotherAppointment(propertyMatch())"
  [enableImageGallery]="enableImageGallery()"
  [enableExportAppointmentToCalendar]="enableExportAppointmentToCalendar"
  [enableDownloadPDF]="showExposeDownload(propertyMatch())"
  [applicationStatus]="propertyMatch()?.status"
  [actionBadgeContent]="actionBadgeContent"
  (notificationBellClickEvent)="showDetailsClick(propertyMatch())"
  (answerCustomQuestionsClickEvent)="onAnswerCustomQuestion(propertyMatch())"
  (declareIntentClickEvent)="
    setIntent(propertyMatch(), applicationStates.INTENT)
  "
  (denyIntentClickEvent)="
    setIntent(propertyMatch(), applicationStates.NO_INTENT)
  "
  (exportAppointmentToCalendarClickEvent)="onExportToCalendar(propertyMatch())"
  (downloadPDFClickEvent)="downloadPDFClick(propertyMatch().id)"
  (removePropertyClickEvent)="removePropertyClick(propertyMatch())"
  (selectAppointmentClickEvent)="onViewInvitation()"
  (showSelfDisclosureClickEvent)="openSelfDisclosureModal(propertyMatch())"
  (showDetailsClickEvent)="showDetailsClick(propertyMatch())"
>
</app-property-card>
