import { Component, input, output } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LocationSearchResultEntry } from '@ui/legacy-lib';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from '@ui/legacy-lib';

@Component({
  selector: 'app-schufa-address',
  templateUrl: './schufa-address.component.html',
  styleUrls: ['./schufa-address.component.scss'],

  imports: [FormsModule, ReactiveFormsModule, ComponentsModule, TranslateModule]
})
export class SchufaAddressComponent {
  readonly form = input<FormGroup>(undefined);
  readonly addressTitle = input<string>(undefined);
  readonly addressHint = input<string>(undefined);
  readonly removable = input<boolean>(undefined);
  readonly index = input<number>(undefined);
  readonly useStreetForSearch = input<boolean>(undefined);
  readonly remove = output();

  public onLocationSearchSelect(value: LocationSearchResultEntry) {
    this.form().patchValue({
      city: value.properties.city,
      street: value.properties.street,
      zipCode: value.properties.postcode,
      houseNumber: value.properties.housenumber
    });
  }

  public onRemove() {
    this.remove.emit();
  }
}
