import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Apollo } from 'apollo-angular';

import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import {
  errorMessageParser,
  getResponseValidator,
  ShowError
} from '@ui/legacy-lib';
import { BrandingTheme, SimpleCustomerBranding } from '@ui/shared/models';

import {
  customerBrandingQuery,
  landlordBrandingQuery
} from '../../core/queries';
import { notificationConfig } from '../../config';
import * as fromActions from './branding.actions';

export interface BrandingResponse {
  landlordBranding: BrandingTheme;
}

export interface CustomerBrandingResponse {
  customerBranding: SimpleCustomerBranding;
}

@Injectable()
export class BrandingEffects {
  private actions$ = inject(Actions);
  private apollo = inject(Apollo);

  validateToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.ValidateThemeToken>(fromActions.VALIDATE_THEME_TOKEN),
      switchMap(({ token, preview }) =>
        this.apollo
          .query({
            query: landlordBrandingQuery,
            variables: {
              input: {
                token,
                preview
              }
            }
          })
          .pipe(
            tap(getResponseValidator<BrandingResponse>()),
            map(
              theme =>
                new fromActions.ValidateThemeTokenSuccess(
                  theme.data.landlordBranding
                )
            ),
            catchError(_err =>
              of(
                new fromActions.ValidateThemeTokenFail(
                  notificationConfig.branding.validate.error
                )
              )
            )
          )
      )
    )
  );

  customerBranding$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.LoadCustomerBranding>(
        fromActions.LOAD_CUSTOMER_BRANDING
      ),
      switchMap(({ customerId }) =>
        this.apollo
          .query({
            query: customerBrandingQuery,
            variables: {
              customerId
            }
          })
          .pipe(
            tap(getResponseValidator<CustomerBrandingResponse>()),
            map(
              response =>
                new fromActions.LoadCustomerBrandingSuccess(
                  response.data.customerBranding
                )
            ),
            catchError(err => {
              return [
                new fromActions.LoadCustomerBrandingFail(err.message),
                new ShowError(errorMessageParser(err))
              ];
            })
          )
      )
    )
  );
}
