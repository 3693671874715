import { Component, input, output } from '@angular/core';
import { PropertyApplicationStatus } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from '@ui/legacy-lib';

@Component({
  selector: 'app-intent-buttons',
  templateUrl: './intent-buttons.component.html',
  styleUrls: ['./intent-buttons.component.scss'],

  imports: [ComponentsModule, TranslateModule]
})
export class IntentButtonsComponent {
  readonly disabled = input<boolean>(undefined);

  readonly setIntent = output<PropertyApplicationStatus>();

  public onSetIntent(selection: boolean) {
    this.setIntent.emit(
      selection
        ? PropertyApplicationStatus.INTENT
        : PropertyApplicationStatus.NO_INTENT
    );
  }
}
