import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import {
  Appointment,
  AppointmentAcceptanceShortInfo,
  AppointmentInvitationState,
  AppointmentPropertyShortInfo,
  AppointmentsBundles,
  Constructor,
  PropertyMatchBean
} from '@ui/shared/models';
import { ExportCalendarFile } from '../../../../../../../../+state';

export interface AppointmentActions {
  /* eslint-disable @typescript-eslint/ban-types */
  getAppointmentsData: Function;
  onExportToCalendar: Function;
  isSelected: Function;
  filterAppointments: Function;
  /* eslint-enable @typescript-eslint/ban-types */
}

export type AppointmentsActionsCtor = Constructor<AppointmentActions>;

export function mixinAppointmentsActions<TBase extends Constructor>(
  Base: TBase
): AppointmentsActionsCtor & TBase {
  return class extends Base {
    public profileComplete$: Observable<boolean>;
    public item?: PropertyMatchBean;
    private store: Store;

    public getAppointmentsData(appointmentsBundles: AppointmentsBundles) {
      const appointments = appointmentsBundles.appointments
        .filter(
          appointment =>
            appointment.maxInviteeCount > appointment.sizeOfAttendees
        )
        .filter(
          appointment =>
            !appointmentsBundles.appointmentInvitations.some(invitation => {
              return invitation.appointmentId === appointment.id;
            })
        );

      const exclusiveAppointments = appointmentsBundles.appointments
        .filter(
          appointment =>
            appointment.maxInviteeCount > appointment.sizeOfAttendees
        )
        .filter(appointment =>
          appointmentsBundles.appointmentInvitations.some(invitation => {
            return invitation.appointmentId === appointment.id;
          })
        );

      return { ...appointmentsBundles, appointments, exclusiveAppointments };
    }

    public onExportToCalendar(application: {
      property: AppointmentPropertyShortInfo;
      appointment: Appointment;
    }) {
      this.store.dispatch(new ExportCalendarFile(application));
    }

    public isSelected(
      appointmentAcceptances: AppointmentAcceptanceShortInfo[],
      appointments: Appointment[]
    ) {
      if (appointmentAcceptances && appointmentAcceptances.length === 0) return;
      return !!appointmentAcceptances
        .filter(item => item.state === AppointmentInvitationState.ACTIVE)
        .find(
          item =>
            !!appointments
              .filter(
                appointment =>
                  appointment.state === AppointmentInvitationState.ACTIVE
              )
              .find(appointment => item.appointmentId === appointment.id)
        );
    }

    public filterAppointments(
      appointments: Appointment[],
      appointmentAcceptances: AppointmentAcceptanceShortInfo[]
    ) {
      return appointments.filter(
        appointment =>
          (appointment.state === AppointmentInvitationState.ACTIVE &&
            !appointment.full) ||
          appointmentAcceptances.some(
            appointmentAcceptance =>
              appointmentAcceptance.appointmentId === appointment.id
          )
      );
    }
  };
}
