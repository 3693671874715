import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { ShowInfo } from '@ui/legacy-lib';
import { MovingChecklistFacade } from '../../core';
import { notificationConfig, storageKeys } from '../../config';
import * as fromActions from './moving-checklist.actions';

@Injectable()
export class MovingChecklistEffects {
  private actions$ = inject(Actions);
  private store = inject(Store);
  private facade = inject(MovingChecklistFacade);

  movingChecklistGetByProperty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.MovingChecklistGetByProperty),
      switchMap(() =>
        this.facade.getMovingChecklistByPropertyID().pipe(
          map(
            movingChecklist =>
              fromActions.MovingChecklistGetByPropertySuccess({
                movingChecklist
              }),
            sessionStorage.removeItem(storageKeys.propertyIdToApply)
          ),
          catchError(error => [
            fromActions.MovingChecklistGetByPropertyFail({ error })
          ])
        )
      )
    )
  );

  setMoveInDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.MovingChecklistSetMoveInDate),
      switchMap(data =>
        this.facade.setMoveInDate(data).pipe(
          mergeMap(movingChecklist => [
            fromActions.MovingChecklistSetMoveInDateSuccess({
              movingChecklist
            }),
            new ShowInfo(
              notificationConfig.movingChecklist.setMoveInDate.success
            )
          ]),
          catchError(error => [
            fromActions.MovingChecklistSetMoveInDateFail({ error })
          ])
        )
      )
    )
  );

  changeElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.MovingChecklistChangeElement),
      switchMap(({ element }) =>
        this.facade.changeElement(element).pipe(
          mergeMap(movingChecklist => [
            fromActions.MovingChecklistChangeElementSuccess({
              movingChecklist
            }),
            new ShowInfo(
              notificationConfig.movingChecklist.changeElement.success
            )
          ]),
          catchError(error => [
            fromActions.MovingChecklistChangeElementFail({ error })
          ])
        )
      )
    )
  );

  reset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.MovingChecklistReset),
      switchMap(() =>
        this.facade.reset().pipe(
          mergeMap(movingChecklist => [
            fromActions.MovingChecklistResetSuccess({
              movingChecklist
            }),
            new ShowInfo(
              notificationConfig.movingChecklist.changeElement.success
            )
          ]),
          catchError(error => [fromActions.MovingChecklistResetFail({ error })])
        )
      )
    )
  );
}
