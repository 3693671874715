import { Component, OnInit, inject, input, output } from '@angular/core';

import { DownloadService } from '@ui/legacy-lib';
import { BaseStep } from '@ui/legacy-lib';
import { PropertySearcherUser, SearchProfile } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { ProfileSummaryComponent } from '../profile-summary/profile-summary.component';

@Component({
  selector: 'app-profile-review',
  templateUrl: './profile-review.component.html',
  styleUrls: ['./profile-review.component.scss'],

  imports: [ProfileSummaryComponent, TranslateModule]
})
export class ProfileReviewComponent extends BaseStep implements OnInit {
  private downloadService = inject(DownloadService);

  readonly userData = input<PropertySearcherUser>(undefined);
  readonly guestMode = input(false);
  readonly searchProfiles = input<SearchProfile[]>(undefined);
  readonly schufaCard = input<boolean>(undefined);
  readonly editSearchProfile = output<SearchProfile>();
  readonly deleteSearchProfile = output<string>();
  readonly addSearchProfile = output();

  public onEditSearchProfile(searchProfile: SearchProfile) {
    this.editSearchProfile.emit(searchProfile);
  }

  public onDeleteSearchProfile(id: string) {
    this.deleteSearchProfile.emit(id);
  }

  public onAddSearchProfile() {
    this.addSearchProfile.emit();
  }
}
