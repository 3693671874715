import { OnInit, Directive, input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { WizardStep } from '@ui/legacy-lib';
import { Step } from '@ui/legacy-lib';

// noinspection AngularMissingOrInvalidDeclarationInModule
@Directive({})
export class RentDepositBaseStep extends WizardStep implements OnInit {
  readonly form = input<FormGroup>(undefined);
  readonly stepIndex = input<number>(undefined);
  readonly steps = input<Step[]>(undefined);

  completeStep() {
    this.onComplete.emit();
  }

  nextStep() {
    const next = this.stepIndex() + 1;
    const stepName = this.steps()[next].name;
    this.onNextStep.emit(stepName);
  }

  previousStep() {
    const next = this.stepIndex() - 1;
    const stepName = this.steps()[next].name;
    this.onPreviousStep.emit(stepName);
  }
}
