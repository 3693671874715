import { Directive, inject, input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import { BaseState, TooltipDirective } from '@ui/legacy-lib';
import {
  continueTooltipProgress,
  getTooltipProgress,
  resetTooltipProgress,
  setTooltipsSeen,
  setTooltipTourSeen
} from '../../+state';

@UntilDestroy()
@Directive({
  selector: '[appTenantTooltip]'
})
export class TenantTooltipDirective extends TooltipDirective implements OnInit {
  readonly appTooltipAnchor = input<string>(undefined);
  readonly appTooltipUseMobileStrategy = input(false);
  private store = inject<Store<BaseState>>(Store);

  ngOnInit() {
    super.ngOnInit();

    this.store
      .select(getTooltipProgress)
      .pipe(untilDestroyed(this))
      .subscribe(
        progress => (this.componentRef.instance.tooltipProgress = progress)
      );

    this.componentRef.instance.continueEvent.subscribe(tooltip => {
      this.store.dispatch(setTooltipsSeen({ ids: [tooltip.id] }));
      this.store.dispatch(continueTooltipProgress());
    });

    this.componentRef.instance.closeEvent.subscribe(tooltipTour => {
      this.store.dispatch(setTooltipTourSeen({ tooltipTour }));
      this.store.dispatch(resetTooltipProgress());
    });
  }
}
