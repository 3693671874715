import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LightboxModule } from 'ng-gallery/lightbox';

import { AngularSvgIconModule } from 'angular-svg-icon';

import {
  ComponentsModule as SharedComponentsModule,
  DigitalContractFormService,
  PipesModule,
  SelfDisclosureService,
  SvgService
} from '@ui/legacy-lib';

import { LottieComponent, provideLottieOptions } from 'ngx-lottie';

import { PaymentsService } from '../core/services/payments.service';
import { NewUserModalComponent } from './new-user-modal/new-user-modal.component';
import { ApplyConfirmationComponent } from './apply-confirmation/apply-confirmation.component';
import { ProfileCompletenessComponent } from './profile-completeness/profile-completeness.component';
import { SearchProfileDetailsModalComponent } from './search-profile-details-modal/search-profile-details-modal.component';
import { TenantAcceptanceModalComponent } from './tenant-acceptance-modal/tenant-acceptance-modal.component';
import { AppointmentSuccessModalComponent } from './appointment-success-modal/appointment-success-modal.component';
import { CustomerInfoLegacyComponent } from './customer-info-legacy/customer-info-legacy.component';
import { ContractHelpModalComponent } from './digital-contract/components/contract-help-modal/contract-help-modal.component';
import { ConfirmIbanModalComponent } from './digital-contract/components/confirm-iban-modal/confirm-iban-modal.component';
import { CustomQuestionsModalComponent } from './custom-questions-modal/custom-questions-modal.component';

import { selfDisclosureComponents } from './self-disclosure';

import { schufaComponents } from './schufa';

import { PropertyInfoComponent } from './property-info/property-info.component';
import { IntentButtonsComponent } from './intent-buttons/intent-buttons.component';
import { PropertyActionButtonComponent } from './property-action-button/property-action-button.component';

import { digitalContractComponents } from './digital-contract';
import { ProfileDkExplanationComponent } from './profile-dk-explanation/profile-dk-explanation.component';
import { ProfileDkZeroComponent } from './profile-dk-zero/profile-dk-zero.component';
import { ProfileDkOneComponent } from './profile-dk-one/profile-dk-one.component';
import { ProfileDkThreeComponent } from './profile-dk-three/profile-dk-three.component';
import { ProfileReviewComponent } from './profile-review/profile-review.component';
import { ProfileSummaryComponent } from './profile-summary/profile-summary.component';
import { GuestRegisterModalComponent } from './guest-register-modal/guest-register-modal.component';
import { PropertyExposeComponent } from './property-expose/property-expose.component';

import { rentalDepositComponents } from './rent-deposit-wizard';
import { movingAuctionComponents } from './moving-auction';
import { TenantExperienceModalComponent } from './tenant-experience-modal/tenant-experience-modal.component';
import { CostDetailsCellComponent } from './property-expose/components/cost-details-cell/cost-details-cell.component';
import { TenantTooltipDirective } from './tenant-tooltip/tenant-tooltip.directive';
import { PaymentPositionsRequestComponent } from './payment-positions-request/payment-positions-request.component';
import { SocialLoginConfirmationModalComponent } from './social-login-confirmation-modal/social-login-confirmation-modal.component';
import { PaymentPositionsRequestAlreadyPaidModalComponent } from './payment-positions-request-already-paid-modal/payment-positions-request-already-paid-modal.component';
import { PaymentPositionsRequestFeedbackModalComponent } from './payment-positions-request-feedback-modal/payment-positions-request-feedback-modal.component';
import { ConfirmApplyModalComponent } from './confirm-apply-modal/confirm-apply-modal.component';
import { ApplicationActionsComponent } from './application-actions/application-actions.component';
import { PropertyCardTenantComponent } from './property-card-tenant/property-card-tenant.component';
import { AppointmentsPropertyItemComponent } from './appointments-property-item/appointments-property-item.component';
import { MovingChecklistCardComponent } from './moving-checklist-card/moving-checklist-card.component';

const components = [
  NewUserModalComponent,
  ApplyConfirmationComponent,
  ProfileCompletenessComponent,
  SearchProfileDetailsModalComponent,
  TenantAcceptanceModalComponent,
  CustomerInfoLegacyComponent,
  PropertyInfoComponent,
  IntentButtonsComponent,
  PropertyActionButtonComponent,
  AppointmentSuccessModalComponent,
  ContractHelpModalComponent,
  ConfirmIbanModalComponent,
  ConfirmApplyModalComponent,
  ProfileDkExplanationComponent,
  ProfileDkZeroComponent,
  ProfileDkOneComponent,
  ProfileDkThreeComponent,
  ProfileReviewComponent,
  ProfileSummaryComponent,
  GuestRegisterModalComponent,
  PropertyExposeComponent,
  ApplicationActionsComponent,
  PropertyCardTenantComponent,
  CustomQuestionsModalComponent,
  AppointmentsPropertyItemComponent,
  TenantExperienceModalComponent,
  MovingChecklistCardComponent,
  CostDetailsCellComponent,
  TenantTooltipDirective,
  PaymentPositionsRequestComponent,
  PaymentPositionsRequestAlreadyPaidModalComponent,
  PaymentPositionsRequestFeedbackModalComponent,
  SocialLoginConfirmationModalComponent,
  ...schufaComponents,
  ...selfDisclosureComponents,
  ...digitalContractComponents,
  ...rentalDepositComponents,
  ...movingAuctionComponents
];

@NgModule({
  imports: [
    TranslateModule,
    CommonModule,
    SharedComponentsModule,
    PipesModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    AngularSvgIconModule,
    LightboxModule,
    LottieComponent,
    ...components
  ],
  providers: [
    SelfDisclosureService,
    DigitalContractFormService,
    SvgService,
    CurrencyPipe,
    PaymentsService,
    provideLottieOptions({
      player: () => import('lottie-web')
    })
  ],
  exports: [...components]
})
export class ComponentsModule {}
