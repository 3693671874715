<div class="moving-auction-address container-padding">
  <div class="row">
    <div class="col-12 col-xl-7">
      <div class="mb16">
        <span class="moving-auction-address__title">{{
          'moving_auction.wizard_address.title' | translate
        }}</span>
      </div>
      <div class="mb16 d-flex align-items-center">
        <span>{{ 'general.in_cooperation_with' | translate }}</span>
        <div class="pl24 moving-auction-address__cooperation-logo">
          <img
            class="immomio-logo"
            src="/assets/images/logos/umzugsauktion_logo.svg"
            alt="umzugsauktion – Wir bewegen was."
          />
        </div>
      </div>
    </div>
    <div class="col-0 col-xl-5">
      <div class="moving-auction-address__advertisement-header">
        <ng-container *ngTemplateOutlet="advertisementTemplate"></ng-container>
      </div>
    </div>
  </div>

  <form [formGroup]="formGroup" class="moving-auction-address__form-area">
    <div withoutPadding="true" class="moving-auction-address__card mb20">
      <div class="moving-auction-address__field mb12">
        {{ 'moving_auction.wizard_address.current_t' | translate }}
      </div>
      <app-address-form
        [formGroup]="currentAddressForm"
        [showLabel]="false"
        [required]="true"
        [showDistricts]="false"
        [showRegion]="true"
        [config]="addressFormConfig"
      >
      </app-address-form>
    </div>

    <div class="moving-auction-address__illustration">
      <img
        class="moving-auction-address__image"
        src="/assets/images/umzugsauktion_transporter.svg"
      />
    </div>

    <div class="moving-auction-address__card mb20">
      <div class="moving-auction-address__field mb12">
        {{ 'moving_auction.wizard_address.new_t' | translate }}
      </div>

      @if (hasMultipleRentedObjects) {
        <div class="row mb8">
          <div class="col-12">
            <app-form-field>
              <app-select
                [items]="rentedObjects()"
                [placeholder]="'general.select_l' | translate"
                formControlName="selectAddress"
              >
              </app-select>
            </app-form-field>
          </div>
        </div>
      }

      <div class="row">
        <div class="col-12">
          <app-address-form
            [formGroup]="newAddressForm"
            [showLabel]="false"
            [showDistricts]="false"
            [showRegion]="true"
            [config]="addressFormConfig"
          >
          </app-address-form>
        </div>
      </div>
    </div>
  </form>

  <div class="row moving-auction-address__advertisement-bottom">
    <ng-container *ngTemplateOutlet="advertisementTemplate"></ng-container>
  </div>

  <div class="row">
    <div class="col-12 p0">
      <app-wizard-footer
        [hasNext]="true"
        [hasPrevious]="false"
        (nextStep)="onCheckForm()"
      >
      </app-wizard-footer>
    </div>
  </div>
</div>

<ng-template #advertisementTemplate>
  <div class="moving-auction-address__advertisement-content">
    <img
      class="moving-auction-address__advertisement-cert"
      src="/assets/images/certificates/zerti_gold_new.png"
      alt="eKomi Kundenauszeichnung"
    />
    <div>
      <div class="row mb14">
        <div class="col-12">
          <svg-icon
            src="/assets/images/icons/icon-check-blue.svg"
            [applyClass]="true"
            [svgStyle]="{ 'width.px': 30 }"
            class="polyline rect btn-default"
          ></svg-icon>
          <span>{{
            'moving_auction.wizard_address.advertisement_item_one' | translate
          }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <svg-icon
            src="/assets/images/icons/icon-check-blue.svg"
            [applyClass]="true"
            [svgStyle]="{ 'width.px': 30 }"
            class="polyline rect btn-default"
          ></svg-icon>
          <span>{{
            'moving_auction.wizard_address.advertisement_item_two' | translate
          }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <svg-icon
            src="/assets/images/icons/icon-check-blue.svg"
            [applyClass]="true"
            [svgStyle]="{ 'width.px': 30 }"
            class="polyline rect btn-default"
          ></svg-icon>
          <span>{{
            'moving_auction.wizard_address.advertisement_item_three' | translate
          }}</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
