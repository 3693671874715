import { Action } from '@ngrx/store';

import { PropertySearcherUser } from '@ui/shared/models';
import {
  SchufaPersonalInformation,
  SendSchufaDelivery as ISchufaDelivery,
  SendSchufaPayment as ISchufaPayment
} from '../../models';
import {
  SchufaDeliveryResponseData,
  SchufaOrderResponseData
} from '../../core/queries';

export const INITIAL_STATE = '[Schufa] Initial State';

export class InitialState implements Action {
  readonly type = INITIAL_STATE;
}

export const NEXT_STEP = '[Schufa] Next Step';

export class NextStep implements Action {
  readonly type = NEXT_STEP;

  constructor(
    public completedStep: string,
    public userData?: PropertySearcherUser
  ) {}
}

export const PREVIOUS_STEP = '[Schufa] Previous Step';

export class PreviousStep implements Action {
  readonly type = PREVIOUS_STEP;
}

export const SEND_SCHUFA_PERSONAL_DATA = '[Schufa] Send Schufa Personal Data';
export const SEND_SCHUFA_PERSONAL_DATA_SUCCESS =
  '[Schufa] Send Schufa Personal Data Success';
export const SEND_SCHUFA_PERSONAL_DATA_FAIL =
  '[Schufa] Send Schufa Personal Data Fail';

export class SendSchufaPersonalData implements Action {
  readonly type = SEND_SCHUFA_PERSONAL_DATA;

  constructor(public personalInformation: SchufaPersonalInformation) {}
}

export class SendSchufaPersonalDataSuccess implements Action {
  readonly type = SEND_SCHUFA_PERSONAL_DATA_SUCCESS;

  constructor(public schufaResponse: SchufaOrderResponseData) {}
}

export class SendSchufaPersonalDataFail implements Action {
  readonly type = SEND_SCHUFA_PERSONAL_DATA_FAIL;

  constructor(public schufaResponse: SchufaOrderResponseData) {}
}

export const SEND_SCHUFA_PAYMENT = '[Schufa] Send Schufa Payment';
export const SEND_SCHUFA_PAYMENT_SUCCESS =
  '[Schufa] Send Schufa Payment Success';
export const SEND_SCHUFA_PAYMENT_FAIL = '[Schufa] Send Schufa Payment Fail';

export class SendSchufaPayment implements Action {
  readonly type = SEND_SCHUFA_PAYMENT;

  constructor(public payment: ISchufaPayment) {}
}

export class SendSchufaPaymentSuccess implements Action {
  readonly type = SEND_SCHUFA_PAYMENT_SUCCESS;

  constructor(public schufaResponse: SchufaOrderResponseData) {}
}

export class SendSchufaPaymentFail implements Action {
  readonly type = SEND_SCHUFA_PAYMENT_FAIL;

  constructor(public schufaResponse: SchufaOrderResponseData) {}
}

export const SEND_SCHUFA_ORDER = '[Schufa] Send Schufa Order';
export const SEND_SCHUFA_ORDER_SUCCESS = '[Schufa] Send Schufa Order Success';
export const SEND_SCHUFA_ORDER_FAIL = '[Schufa] Send Schufa Order Fail';

export class SendSchufaOrder implements Action {
  readonly type = SEND_SCHUFA_ORDER;
}

export class SendSchufaOrderSuccess implements Action {
  readonly type = SEND_SCHUFA_ORDER_SUCCESS;

  constructor(public schufaResponse: SchufaDeliveryResponseData) {}
}

export class SendSchufaOrderFail implements Action {
  readonly type = SEND_SCHUFA_ORDER_FAIL;

  constructor(public schufaResponse: SchufaDeliveryResponseData) {}
}

export const SEND_SCHUFA_DELIVERY = '[Schufa] Send Schufa Delivery';
export const SEND_SCHUFA_DELIVERY_SUCCESS =
  '[Schufa] Send Schufa Delivery Success';
export const SEND_SCHUFA_DELIVERY_FAIL = '[Schufa] Send Schufa Delivery Fail';

export class SendSchufaDelivery implements Action {
  readonly type = SEND_SCHUFA_DELIVERY;

  constructor(public delivery: ISchufaDelivery) {}
}

export class SendSchufaDeliverySuccess implements Action {
  readonly type = SEND_SCHUFA_DELIVERY_SUCCESS;

  constructor(public schufaResponse: SchufaDeliveryResponseData) {}
}

export class SendSchufaDeliveryFail implements Action {
  readonly type = SEND_SCHUFA_DELIVERY_FAIL;

  constructor(public schufaResponse: SchufaDeliveryResponseData) {}
}

export type SchufaActions =
  | InitialState
  | SendSchufaPersonalData
  | SendSchufaPersonalDataFail
  | SendSchufaPersonalDataSuccess
  | SendSchufaPayment
  | SendSchufaPaymentFail
  | SendSchufaPaymentSuccess
  | SendSchufaOrder
  | SendSchufaOrderFail
  | SendSchufaOrderSuccess
  | SendSchufaDelivery
  | SendSchufaDeliveryFail
  | SendSchufaDeliverySuccess
  | NextStep
  | PreviousStep;
