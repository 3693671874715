@if (showContent && !propertyMatch()?.upcomingAppointmentDate) {
  <div [@fadeOnEnterLeave] class="overlay"></div>
}

<div
  class="application-actions"
  [class.application-actions--embedded]="!isApplyPage()"
  [class.show-content]="showContent"
  [class.upcoming-appointment]="propertyMatch()?.upcomingAppointmentDate"
>
  <div class="application-actions__inner container pt0 pl0 pr0">
    <div class="application-actions__content col p0">
      @if (
        showChooseAppointment &&
        (appointmentsActionState$ | async)?.done &&
        !isApplyPage()
      ) {
        <app-appointment-selection
          [appointments]="appointments"
          [exclusiveAppointments]="exclusiveAppointments"
          [formControl]="newActiveAppointmentId"
        >
        </app-appointment-selection>
      }
      @if (propertyMatch()?.upcomingAppointmentDate && !isApplyPage()) {
        <div class="application-actions__calendar">
          <div class="calendar__time d-flex align-items-center">
            <div class="d-flex align-items-center">
              <svg-icon
                src="/assets/images/icons/calendar.svg"
                [applyClass]="true"
                [svgStyle]="{ 'width.px': 15 }"
                class="secondary-svg-color path rect"
              ></svg-icon>
              <span class="pl5 pr5">{{
                propertyMatch()?.upcomingAppointmentDate | appDateTime
              }}</span>
            </div>
            <div class="d-flex align-items-center">
              <svg-icon
                src="/assets/images/icons/icon-clock.svg"
                [applyClass]="true"
                [svgStyle]="{ 'width.px': 15 }"
                class="secondary-svg-color path circle polyline"
              ></svg-icon>
              <span class="pl10">{{
                propertyMatch()?.upcomingAppointmentDate
                  | appDateTime
                    : {
                        withDate: false,
                        withTime: true
                      }
              }}</span>
            </div>
          </div>
        </div>
      }
      @if (
        showViaEmailForm &&
        isApplyPage() &&
        (isCommercial(property()) || isSales(property()?.marketingType))
      ) {
        <div class="property-expose__apply-anonymouse">
          @if ((applicationDone$ | async) !== true) {
            <form [formGroup]="anonymousApplyForm">
              <div
                class="property-apply-anonymous__info"
                [innerHTML]="'property.apply_anonymous.info_m' | translate"
              ></div>
              <div class="mt8 row">
                <div class="col-6">
                  <app-form-field>
                    <app-form-field-label
                      >{{ 'general.first_name_l' | translate }}
                    </app-form-field-label>
                    <input
                      appInput
                      type="text"
                      [placeholder]="'forms.type_firstname' | translate"
                      formControlName="firstname"
                      class="form-control"
                    />
                  </app-form-field>
                </div>
                <div class="col-6">
                  <app-form-field>
                    <app-form-field-label
                      >{{ 'general.name_l' | translate }}
                    </app-form-field-label>
                    <input
                      appInput
                      type="text"
                      [placeholder]="'forms.type_name' | translate"
                      formControlName="name"
                      class="form-control"
                    />
                  </app-form-field>
                </div>
              </div>
              <div class="mt8 mb8 row">
                <div class="col-6">
                  <app-form-field>
                    <app-form-field-label
                      >{{ 'general.email_l' | translate }}
                    </app-form-field-label>
                    <input
                      appInput
                      type="text"
                      [placeholder]="'forms.type_email' | translate"
                      formControlName="email"
                      class="form-control"
                    />
                  </app-form-field>
                </div>
                <div class="col-6">
                  <app-form-field>
                    <app-form-field-label
                      >{{ 'general.phone_l' | translate }}
                    </app-form-field-label>
                    <input
                      appInput
                      type="text"
                      [placeholder]="'forms.type_phone' | translate"
                      formControlName="phone"
                      class="form-control"
                    />
                  </app-form-field>
                </div>
              </div>
            </form>
          } @else {
            <div class="row justify-content-center">
              <img src="/assets/images/icons/icon-checkbox-green.svg" />
              <span
                class="property-apply-anonymous-success ml8"
                [innerHTML]="'property.apply_anonymous.success_m' | translate"
              ></span>
            </div>
            <div
              class="row justify-content-center mt12 mb20"
              [innerHTML]="
                'property.apply_anonymous.success_contact_m' | translate
              "
            ></div>
          }
        </div>
      }
    </div>
    <div class="application-actions__actions">
      @if (isApplyPage()) {
        @if (isSales(property()?.marketingType)) {
          <app-button
            [disabled]="
              property()?.rented ||
              !salesLegalTextCheckboxesValid() ||
              (applyPageProcessing$ | async) ||
              (salesGuestApplyDone$ | async)
            "
            (clickEvent)="onOpenSalesModal()"
            [type]="'primary'"
          >
            {{
              (property()?.rented
                ? 'property.not_available_a'
                : property()?.marketingType === 'SALES'
                  ? 'property.apply_now_sales_a'
                  : 'property.apply_now_a'
              ) | translate
            }}
          </app-button>
        } @else {
          @if (!showViaEmailForm) {
            <app-button
              [disabled]="disabled() || property()?.rented"
              (clickEvent)="onLandingApply()"
              [type]="'primary'"
            >
              {{
                (property()?.rented
                  ? 'property.not_available_a'
                  : 'property.apply_now_a'
                ) | translate
              }}
            </app-button>
          } @else {
            <app-button
              (clickEvent)="onApplyAnonymous(anonymousApplyForm.value)"
              [disabled]="
                property()?.rented ||
                anonymousApplyForm.invalid ||
                (applyPageProcessing$ | async)
              "
              [loading]="applyPageProcessing$ | async"
              [type]="'primary'"
            >
              {{
                (property()?.rented
                  ? 'property.not_available_a'
                  : 'property.apply_anonymous_now_a'
                ) | translate
              }}
            </app-button>
          }
        }
      } @else {
        <div class="row m-0">
          @if (
            buttonType === applicantStatus.NEW_MATCH ||
            buttonType === applicantStatus.AUTO_OFFERED ||
            buttonType === applicantStatus.OFFERED
          ) {
            <app-button
              (clickEvent)="onRemoveProperty(propertyMatch())"
              [disabled]="disabled()"
              class="col-6 pl0 pr5"
              [type]="'negative'"
            >
              {{ 'property.remove_property_proposal_a' | translate }}
            </app-button>
          }
          @if (
            buttonType === applicantStatus.NEW_MATCH ||
            buttonType === applicantStatus.AUTO_OFFERED ||
            buttonType === applicantStatus.OFFERED
          ) {
            <app-button
              (clickEvent)="onApply(propertyMatch())"
              [disabled]="disabled()"
              class="col-6 pr0 pl5"
              [type]="'positive'"
            >
              {{ 'property.apply_now_a' | translate }}
            </app-button>
          }
        </div>
        @if (waitForLandlord) {
          <div>
            @if ((profileComplete$ | async) !== true) {
              <app-button
                [type]="'description'"
                [elevationHoverEffect]="false"
                [iconLeft]="'clock'"
                [disableHover]="true"
              >
                {{ 'properties.actions.waiting_for_landlord_l' | translate }}
              </app-button>
            }
            @if (profileComplete$ | async) {
              <app-button
                [disabled]="disabled()"
                (clickEvent)="editProfile()"
                [type]="'primary'"
                >{{ 'profile.edit_profile_a' | translate }}
              </app-button>
            }
          </div>
        }
        @if (buttonType === applicantStatus.NO_OPEN_SLOTS) {
          <app-button
            [type]="'description'"
            [elevationHoverEffect]="false"
            [disableHover]="true"
          >
            {{ 'properties.banner.no_open_slots_available_l' | translate }}
          </app-button>
        }
        @if (
          buttonType === applicantStatus.INVITED_TO_VIEWING ||
          buttonType === applicantStatus.ATTENDING_TO_VIEWING ||
          buttonType === applicantStatus.ALL_APPOINTMENTS_DECLINED
        ) {
          @if (appointments?.length || exclusiveAppointments?.length) {
            @if (
              !showChooseAppointment &&
              propertyMatch().status ===
                applicantStatus.ALL_APPOINTMENTS_DECLINED &&
              buttonType !== applicantStatus.DECLARE_INTENT
            ) {
              <div class="appointment__non-fitting">
                <svg-icon
                  src="/assets/images/icons/icon-no.svg"
                  [applyClass]="true"
                  [svgStyle]="{ 'width.px': 15 }"
                  class="white-svg-color path"
                ></svg-icon>
                <span class="pl10">
                  {{ 'appointment.appointment_not_fitting' | translate }}
                </span>
              </div>
            }
            @if (
              (buttonType === applicantStatus.INVITED_TO_VIEWING ||
                buttonType === applicantStatus.ALL_APPOINTMENTS_DECLINED) &&
              !showChooseAppointment
            ) {
              <app-button
                (clickEvent)="showAppointments()"
                [disabled]="disabled()"
                [type]="'primary'"
              >
                @if (buttonType !== applicantStatus.ALL_APPOINTMENTS_DECLINED) {
                  {{ 'property.view_invitation_a' | translate }}
                } @else {
                  {{ 'appointment.change_decision_l' | translate }}
                }
              </app-button>
            }
            @if (
              (buttonType === applicantStatus.INVITED_TO_VIEWING ||
                buttonType === applicantStatus.ALL_APPOINTMENTS_DECLINED) &&
              showChooseAppointment
            ) {
              <app-button
                (clickEvent)="onSelectAppointment()"
                [disabled]="!newActiveAppointmentId.value"
                [type]="'primary'"
              >
                @if (newActiveAppointmentId.value !== 'non_fitting') {
                  {{ 'property.select_appointment_a' | translate }}
                } @else {
                  {{ 'appointment.non_fitting_selected_a_l' | translate }}
                }
              </app-button>
            }
          } @else {
            @if (!propertyMatch()?.upcomingAppointmentDate) {
              <app-button
                [type]="'description'"
                [elevationHoverEffect]="false"
                [disableHover]="true"
                [iconLeft]="'clock'"
              >
                {{
                  'properties.actions.no_appointments_available_a' | translate
                }}
              </app-button>
            }
          }
        }
        @if (buttonType === applicantStatus.ATTENDING_TO_VIEWING) {
          <app-button
            (clickEvent)="onCancelAppointment(activeAppointmentAcceptance.id)"
            [disabled]="(appointmentsItemActionState$ | async)?.pending"
            [type]="'negative'"
          >
            {{ 'appointment.cancel_viewing_a' | translate }}
          </app-button>
        }
        @if (buttonType === applicantStatus.DECLARE_INTENT) {
          <div class="row m0 intent-buttons__container">
            <app-intent-buttons
              [disabled]="disabled()"
              (setIntent)="setIntent($event)"
            >
            </app-intent-buttons>
          </div>
        }
      }
    </div>
  </div>
</div>
