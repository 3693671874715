<div class="digital-contract-code-verify">
  <form [formGroup]="form()">
    <div class="row">
      <div class="col">
        <img
          class="dmv-success-image"
          src="/assets/images/icons/icon-checkbox-green.svg"
        />
        {{ 'digital_contract.wizard_sign.id_check_success_l' | translate }}
      </div>
    </div>

    <div class="row mt20 id-check-success-container">
      <div class="col-12">
        {{ 'digital_contract.wizard_sign.id_check_success_text_l' | translate }}
      </div>
    </div>

    <div class="row mt20 digital-contract-code-verify__how-to">
      <div class="col">
        <img
          class="banner-logo"
          src="assets/images/digitalContract/Verifizierungscode.svg"
        />
      </div>
    </div>

    <div class="row code-verification-container mt20 mb50 ml0 mr0">
      <div class="col-12 code-verification-container__text">
        {{
          'digital_contract.wizard_sign.verify_code_text_l'
            | translate
              : {
                  iban: getIban
                }
        }}
      </div>

      <div class="col-12 mt20">
        <app-form-field>
          <app-form-field-label
            >{{ 'digital_contract.wizard_sign.aes_code_l' | translate }}
          </app-form-field-label>
          <input
            appInput
            type="text"
            [required]="true"
            [placeholder]="'forms.type_code' | translate"
            formControlName="aesCodeInput"
            class="form-control"
          />
        </app-form-field>
      </div>

      <div class="mt30">
        @if (isRetry) {
          <div class="row">
            <div class="verification-error col-12">
              <div class="verification-error__header">
                {{
                  'digital_contract.sign_wizard.retry_code_verification_l'
                    | translate
                }}
              </div>
            </div>
          </div>
        }
        @if (notAllowed || isError) {
          <div class="row">
            <div class="verification-error col-12">
              <div class="verification-error__header">
                {{
                  'digital_contract.sign_wizard.code_verification_not_allowed_l'
                    | translate
                }}
              </div>
              <div>
                {{
                  'digital_contract.sign_wizard.id_error_contract_landlord_l'
                    | translate
                }}
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  </form>
</div>
