import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  MovingAuctionResponse,
  MovingAuctionResponseType
} from '@ui/shared/models';
import {
  ButtonComponent,
  ModalContentComponent,
  ModalFooterComponent,
  ModalV2Component
} from '@ui/legacy-lib';
import { SvgIconComponent } from 'angular-svg-icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-moving-auction-modal',
  templateUrl: './moving-auction-modal.component.html',
  styleUrls: ['./moving-auction-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ModalV2Component,
    ModalContentComponent,
    SvgIconComponent,
    TranslateModule,
    ModalFooterComponent,
    ButtonComponent
  ]
})
export class MovingAuctionModalComponent {
  private ngbActiveModal = inject(NgbActiveModal);

  readonly movingAuctionResponse = input<MovingAuctionResponse>(undefined);

  public get isCreated() {
    return (
      this.movingAuctionResponse().responseType ===
      MovingAuctionResponseType.CREATED
    );
  }

  public get isAccepted() {
    return (
      this.movingAuctionResponse().responseType ===
      MovingAuctionResponseType.ACCEPTED
    );
  }

  public get hasError() {
    return (
      this.movingAuctionResponse().responseType ===
      MovingAuctionResponseType.ERROR
    );
  }

  public close() {
    this.ngbActiveModal.dismiss();
  }
}
