import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { first } from 'rxjs';
import { ThemeService } from '@ui/legacy-lib';
import { fetchPaymentBranding } from '../../+state/payments/payments.actions';
import { getPaymentBranding } from '../../+state/payments/payments.selectors';

@Injectable()
export class PaymentsService {
  private store = inject(Store);
  private themeService = inject(ThemeService);

  public applyBranding(token: string): void {
    this.store.dispatch(fetchPaymentBranding({ token }));
    this.store
      .select(getPaymentBranding)
      .pipe(first(branding => !!branding))
      .subscribe(branding => this.themeService.createTheme(branding));
  }
}
