import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, inject, OnInit, input, output } from '@angular/core';
import {
  NavigationStart,
  Router,
  RouterLink,
  RouterLinkActive
} from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { Location } from '@angular/common';

import { desktopBreakingPoints, mobileBreakingPoints } from '@ui/legacy-lib';
import { PropertySearcherUser } from '@ui/shared/models';

import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { NavigationItem } from '../../../../models';
import { MainPageNavigation } from '../../../../config';

@UntilDestroy()
@Component({
  selector: 'app-mobile-navigation',
  templateUrl: './mobile-navigation.component.html',
  styleUrls: ['./mobile-navigation.component.scss'],

  imports: [RouterLinkActive, RouterLink, SvgIconComponent, TranslateModule]
})
export class MobileNavigationComponent implements OnInit {
  readonly navigationItems = input<NavigationItem[]>([]);
  readonly user = input<PropertySearcherUser>(undefined);
  readonly unreadCount = input<number>(undefined);
  readonly tenantInInternalPool = input<boolean>(undefined);
  readonly displayTopNavigation = input<boolean>(undefined);
  readonly hasNewHome = input<boolean>(undefined);
  readonly openSearch = output();
  readonly logout = output<void>();
  public sidenavOpened = false;
  public visibleItems: NavigationItem[];
  public isMobile = false;
  private router = inject(Router);
  private observer = inject(BreakpointObserver);
  private location = inject(Location);

  public ngOnInit() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe(() => this.closeSidenav());

    // TODO Use imported breakpoints after https://immomio.atlassian.net/browse/ART-376 is finished.
    const workAroundMobileBP = [...mobileBreakingPoints];
    const workAroundDesktopBP = [...desktopBreakingPoints];
    workAroundMobileBP[workAroundMobileBP.length - 1] =
      '(min-width: 600px) and (max-width: 991.98px)';
    workAroundDesktopBP[0] = '(min-width: 992px) and (max-width: 1279.98px)';

    this.observer
      .observe([...workAroundMobileBP, ...workAroundDesktopBP])
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (workAroundMobileBP.some(bp => result.breakpoints[bp])) {
          this.isMobile = result.matches;
          this.computeVisibleNavigationItems(result.matches, true);
        }
        if (workAroundDesktopBP.some(bp => result.breakpoints[bp])) {
          this.isMobile = false;
          this.computeVisibleNavigationItems(result.matches, false);
        }
      });
  }

  public getItemLink(item: NavigationItem) {
    if (
      !this.hasNewHome() &&
      item.link.includes(MainPageNavigation.NEW_HOME.toLowerCase())
    ) {
      return `${MainPageNavigation.PROPERTIES}/${MainPageNavigation.APPLICATIONS}`;
    }
    return item.link;
  }

  public openSearchModal() {
    this.openSearch.emit();
  }

  public openSidenav($event: Event) {
    $event.stopPropagation();

    if (this.sidenavOpened) {
      this.closeSidenav();
      return;
    }

    this.sidenavOpened = true;
  }

  public closeSidenav() {
    if (!this.sidenavOpened) {
      return;
    }

    this.sidenavOpened = false;
  }

  public onLogout() {
    this.logout.emit();
  }

  public setActiveNav(navLink: NavigationItem): boolean {
    const currentPath = this.location.path();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return navLink?.additionalLinks?.some(path => {
      return (
        currentPath.indexOf(path) >= 0 || currentPath.indexOf(navLink.link) >= 0
      );
    });
  }

  private computeVisibleNavigationItems(
    activeBreakpoint: boolean,
    mobile: boolean
  ) {
    this.visibleItems = this.navigationItems()?.filter(item => {
      const hideOnPlatform = mobile ? item.hideOnMobile : item.hideOnDesktop;
      return !(activeBreakpoint ? item.hide || hideOnPlatform : item.hide);
    });
  }
}
