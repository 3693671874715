import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, from, Observable, of, take } from 'rxjs';

import {
  AuthToken,
  AuthTokenService,
  coerceBooleanProperty,
  DeepLinkService,
  KeycloakTenantWebService,
  LocalStorageService,
  SessionStorageService,
  UserLoginSuccess,
  UserRedirect
} from '@ui/legacy-lib';

import { StateAfterAuth } from '@ui/shared/models';
import { switchMap } from 'rxjs/operators';
import { storageKeys } from '../../config';

@Injectable()
export class AuthGuard {
  private sessionStorage = inject(SessionStorageService);
  private store = inject(Store);
  private authTokenService = inject(AuthTokenService);
  private keycloakWebService = inject(KeycloakTenantWebService);
  private deepLinkService = inject(DeepLinkService);
  private localStorageService = inject(LocalStorageService);

  private stateAfterAuth: StateAfterAuth;

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const queryParams = route.queryParams;
    const { socialLogin, isSearching, pathAfterAuth, ...rest } = queryParams;
    this.stateAfterAuth = (
      pathAfterAuth?.length > 0
        ? { ...rest, pathAfterAuth }
        : this.sessionStorage.getItem(storageKeys.stateAfterAuth)
    ) as StateAfterAuth;

    if (this.stateAfterAuth) {
      this.stateAfterAuth.queryParams = {
        // pathAfterAuth should not be included in the query params because it's not used here
        ...rest,
        socialLogin: coerceBooleanProperty(socialLogin),
        isSearching
      };
    }

    // When the path includes query params, cut them off
    // Because they are already set in stateAfterAuth.queryParams
    if (this.stateAfterAuth?.pathAfterAuth?.includes('?'))
      this.stateAfterAuth.pathAfterAuth =
        this.stateAfterAuth.pathAfterAuth?.split('?')[0];

    const ssoParams = route?.fragment
      ? this.deepLinkService.getSSOParams(route)
      : this.authTokenService.getToken();
    const isRedirectedFromKeycloakLogin = !!ssoParams?.code;
    const redirectUri = this.localStorageService.getItem(
      storageKeys.redirectUri
    );

    if (ssoParams?.access_token) {
      return of(true);
    } else if (!isRedirectedFromKeycloakLogin) {
      // If the user has NOT been redirected from the keycloak login site,
      // then the user is not logged in when not token is set
      this.store.dispatch(new UserRedirect(state.url));
    } else {
      // If the user HAS been redirected from the keycloak login site,
      // then the login should continue and the token should be fetched
      return this.keycloakWebService.keycloakInitialized().pipe(
        filter(initialized => initialized),
        take(1),
        switchMap(() => {
          const keycloakInstanceToken =
            this.keycloakWebService.getAuthTokenFromKeycloakInstance();
          if (keycloakInstanceToken) {
            return this.continueLogin(keycloakInstanceToken);
          } else {
            return from(
              this.keycloakWebService.continueLoginWithCode({
                code: ssoParams.code,
                redirectUri
              })
            ).pipe(switchMap(authToken => this.continueLogin(authToken)));
          }
        })
      );
    }
  }

  private continueLogin(authToken: AuthToken) {
    this.localStorageService.removeItem(storageKeys.redirectUri);
    this.store.dispatch(new UserLoginSuccess(authToken, this.stateAfterAuth));
    return of(true);
  }
}
