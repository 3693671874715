import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit
} from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import {
  ButtonComponent,
  ConfirmReasonComponent,
  getConstants,
  Go,
  isRentalProperty,
  LocalStorageService,
  RadioButtonComponent,
  RadioGroupComponent
} from '@ui/legacy-lib';

import {
  DeclareIntentData,
  GuestApplication,
  NameValue,
  PropertyApplicationStatus,
  PropertyBean
} from '@ui/shared/models';

import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { AsyncPipe } from '@angular/common';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { storageKeys } from '../../../../../../config';
import {
  getGuestApplication,
  getGuestDeclareIntentActionState,
  getGuestDeclareIntentLoading,
  GuestDeclareIntent,
  OpenGuestRegisterModal
} from '../../../../../../+state';

@UntilDestroy()
@Component({
  selector: 'app-guest-intent',
  templateUrl: './guest-intent.component.html',
  styleUrls: ['./guest-intent.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TranslateModule,
    ConfirmReasonComponent,
    RadioGroupComponent,
    RadioButtonComponent,
    FormsModule,
    AsyncPipe,
    NgbTooltip,
    ButtonComponent
  ]
})
export class GuestIntentComponent implements OnInit {
  public token: string;
  public application: GuestApplication;
  public property: PropertyBean;
  public hasVisitedViewingAppointment: string;
  public processing$: Observable<boolean>;
  public isInterested: boolean;
  public isNotInterested: boolean;
  public reasonTypeModel: string;
  public otherReasonText: string;
  public reasons: NameValue[];
  private store = inject(Store);
  private route = inject(ActivatedRoute);
  private localStorageService = inject(LocalStorageService);

  public get isSelfDisclosureRequired() {
    return !!this.property?.selfDisclosureId;
  }

  public get isInvalid() {
    return (
      !this.reasonTypeModel ||
      (this.reasonTypeModel === 'OTHER_REASON' && !this.otherReasonText)
    );
  }

  public get isRentalObject(): boolean {
    return isRentalProperty(this.property.marketingType);
  }

  ngOnInit() {
    this.token = this.route.snapshot.queryParams.token || '';
    const value = this.route.snapshot.queryParams.value || '';

    this.isInterested = value === PropertyApplicationStatus.INTENT;
    this.isNotInterested = value === PropertyApplicationStatus.NO_INTENT;

    this.store
      .select(getConstants)
      .pipe(
        filter(constants => !!constants),
        untilDestroyed(this)
      )
      .subscribe(constants => (this.reasons = constants.refusalReasonTypes));

    this.store
      .select(getGuestApplication)
      .pipe(
        filter(item => !!item),
        untilDestroyed(this)
      )
      .subscribe(application => {
        this.application = application;
        this.property = application.property;
      });

    this.processing$ = combineLatest([
      this.store.select(getGuestDeclareIntentLoading)
    ]).pipe(map(list => list.some(item => item)));
  }

  public onClickInterested() {
    if (this.isSelfDisclosureRequired && this.isRentalObject) {
      this.localStorageService.setItem(
        storageKeys.selfDisclosureDeclareIntentId,
        this.property.selfDisclosureId
      );
      this.localStorageService.setItem(
        storageKeys.selfDisclosureApplicationId,
        this.application.id
      );
      this.store.dispatch(
        new OpenGuestRegisterModal(this.application, this.token, true)
      );
    } else {
      this.intentAction({ intent: true }, this.token);
    }
  }

  public denyIntent() {
    const payload = {
      intent: false,
      reasonType: this.reasonTypeModel,
      otherReasonText: this.otherReasonText
    };
    this.intentAction(payload, this.token);
  }

  private watchIntentState() {
    this.store
      .select(getGuestDeclareIntentActionState)
      .pipe(
        filter(item => !!item && item.done),
        take(1)
      )
      .subscribe(() => {
        this.store.dispatch(
          new Go({
            path: ['guest'],
            query: { token: this.token }
          })
        );
      });
  }

  private intentAction(declareIntentData: DeclareIntentData, token: string) {
    this.watchIntentState();
    this.store.dispatch(new GuestDeclareIntent(declareIntentData, token));
  }
}
