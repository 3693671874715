@if (!isSigned() && !hasError()) {
  <div>
    <span>{{
      'digital_contract.wizard_sign.redirect_docusign_l' | translate
    }}</span>
    <div class="mt30 loading-area">
      <app-loading-spinner></app-loading-spinner>
    </div>
  </div>
}
@if (hasError()) {
  <div class="mt40 docusign-error">
    <span>{{
      'digital_contract.wizard_sign.redirect_docusign_error_l' | translate
    }}</span>
  </div>
}
@if (isSigned() && !hasError()) {
  <div>
    <h3>
      {{ 'digital_contract.wizard_sign.signature_done_header_l' | translate }}
    </h3>
    <span>{{
      'digital_contract.wizard_sign.signature_done_text_l' | translate
    }}</span>
    @if (showShopCard()) {
      <div class="mt40 shop__container pb-4">
        <a href="https://umzugsshop.immomio.com" target="_blank">
          <img
            src="{{
              narrowScreen
                ? '/assets/images/umzugsshop_promo_mobile.svg'
                : '/assets/images/umzugsshop_promo.svg'
            }}"
          />
        </a>
      </div>
    }
  </div>
}
